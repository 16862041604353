<template>
  <!-- SIZE SKELETON -->
  <ProductIntroSizeSkeleton
    v-if="!isComplete"
    :attr-list="noCalAttrList"
  />
  <ClientOnly>
    <SizeGroup 
      v-if="sizeGroupPosition === 'mid'"
      :from="from"
      :goods-id="goodsId"
      :language="language"
      :ccc-attr-groups="cccAttrGroups"
      :constant="{
        complianceTipsMode: complianceTipsMode,
        complianceMode: complianceMode,
        isHitComplianceMode: isHitComplianceMode,
      }"
      @handle-click-size-group-item="handleClickSizeGroupItem"
    /> 
    <div v-expose="exposeAnalysisData">
      <div
        v-for="(item, index) in skcSaleAttr"
        v-show="setFoldShow(index) && !hideOneSize"
        :key="item.attr_id"
        class="product-intro__size"
      >
        <div
          class="product-intro__size-title"
          tabindex="0"
          :aria-label="item.attr_name"
        >
          {{ item.attr_name }}
          <template v-if="isNewSizeLocal && hasMultiLocal && item.attr_id == 87 && !index">
            <CommonLocalSizeSelect
              v-model:value="currentSelectedCountry"
              :multi-local-size="multiLocalSize"
              :current-tab-local-is-active="currentTabLocalIsActive"
              :local-size-list="config.localSizeList"
              :show-only-one-local-size="config.showOnlyOneLocalSize"
              :language="language"
              :style="{ backgroundColor: '#F6F6F6', border: 'none', marginLeft: '5px' }"
              :default-text="language.SHEIN_KEY_PC_24641"
              :is-new-size-local="isNewSizeLocal"
              :swap-size-tab-position="config.swapSizeTabPosition"
              @click-handle="sizeSelectClick"
              @update:value="onChangeSelectCountry"
            />
          </template>
          <template v-else-if="item.attr_id == sizeSaleAttrId && country && hasLocalSize">
            <span class="country">({{ currentSelectedCountry }})</span>
          </template>
          <div
            v-if="
              sizeGuideShow && sizeGuideInTitle && item.attr_id == sizeSaleAttrId
            "
            class="product-intro__size-guide-old title"
            da-event-click="1-8-6-6"
            @click="sizeGuideModalShow(undefined, { fromScene: 'cart_title' })"
          >
            <Icon
              name="sui_icon_sizeguide_24px"
              :size="isRw ? '12px' : '24px'"
            />
            <span class="product-intro__size-guide-old-t">
              {{
                language.SHEIN_KEY_PC_16557
              }}
            </span>
          </div>
          <div
            v-if="showSizeErrorTips(String(item.attr_id), index)"
            :ref="`size-error-tips__hook-${index}`"
            class="product-intro__size-error-tips"
            tabindex="-1"
            :aria-describedby="language.SHEIN_KEY_PC_14593"
            :class="{ 'animated product-intro__shake': notSelectedTipsAnim }"
          >
            {{ language.SHEIN_KEY_PC_20101 }} {{ item.attr_name }}
          </div>
        </div>
        <div
          v-if="item.attr_value_list.length || (item.attr_id == 87 && relateGood)"
          ref="product-intro__size-choose"

          class="product-intro__size-choose fsp-element"
          :class="{
            'animated product-intro__shake':
              sizeInfoIndex[item.attr_id].attr_value_id == '' &&
              notSelectedTipsAnim,
          }"
        >
          <!-- 当前商品存在关联普通码 -->
          <s-popover
            v-if="relateGoodType === 0 && item.attr_id == 87 && !sizeGroupPosition && !hideKidRelatedSize && !config.hideRelationPlusSize && !hideSizeRange"
            ref="popoverRef"
            :key="0"
            :class="[
              'relate-good',
              'product-intro__size-radio-spopover',
              `j-product-intro__size-radio-spopover_${item.attr_id}_index0`,
            ]"
            :placemen="sizePopoverPlacemen[0]"
            :delay-render="300"
            trigger="hover"
            :append-to-body="true"
            :empty-un-show="true"
          >
            <template #reference>
              <div
                v-enterkey
                class="product-intro__size-radio"
                tabindex="0"
                @touchstart="showQuickviewModalTouch($event, true, relateGood)"
                @click="showQuickviewModal($event, true, relateGood)"
              >
                <div class="product-intro__size-radio-inner">
                  <span
                    v-for="(subItem, i) in relateGood.sizeText"
                    :key="i"
                    v-html="getRelateSize(subItem)"
                  ></span>
                  <Icon
                    :class="{ 'to-right': from == 'detail' }"
                    name="sui_icon_paly_40px"
                    size="14px"
                    :is-rotate="GB_cssRight"
                    :custom-style="{ verticalAlign: '-2px'}"
                  />
                </div>
              </div>
            </template>
            <div class="product-intro__size-popover-content">
              <div
                class="product-intro__size-popover-img"
                :style="{
                  backgroundImage: `url(${relateGood.goods_thumb})`,
                }"
              ></div>
              <div class="product-intro__size-popover-wrap">
                <div class="product-intro__size-popover-stitle">
                  {{ language.SHEIN_KEY_PC_17358 }}
                </div>
                <div class="product-intro__size-popover-gtitle">
                  {{ relateGoodsName }}
                </div>
                <div class="product-intro__size-popover-gprice">
                  <ProductCardPriceSimpleAutoComplianceMode 
                    :goods-info="relateGood"
                    :is-paid="productIntroData.isPaidUser"
                    :language="language"
                    :config="{
                      showS3VipPriceOnSale: config?.isS3newPrice
                    }"
                  />
                </div>
              </div>
            </div>
          </s-popover>
          <template
            v-for="(subItem, subIndex) in item.attr_value_list"
            :key="subIndex + 1"
          >
            <s-popover
              ref="popoverRef"
              :class="[
                'product-intro__size-radio-spopover',
                `j-product-intro__size-radio-spopover_${item.attr_id}_index${
                  subIndex + 1
                }`,
              ]"
              :placemen="sizePopoverPlacemen[subIndex + 1]"
              :trigger="isMobile ? 'click' : 'hover'"
              :hide-close-icon="true"
              :append-to-body="true"
              :empty-un-show="true"
              :delay-render="300"
              @opened="handleSizeHover(subIndex + 1, subItem, index)"
            >
              <!-- @input="(val) => handleValueChange(val, subItem)" -->
              <template #reference>
                <div
                  v-enterkey
                  class="product-intro__size-radio"
                  :class="[
                    subItem.attr_value_id ==
                      sizeInfoIndex[item.attr_id].attr_value_id &&
                      chooseCheck(
                        item.attr_id,
                        item.attr_name,
                        subItem.attr_value_name,
                        '',
                        subItem.attr_value_id,
                      ) >= 0
                      ? 'product-intro__size-radio_active'
                      : '',
                    chooseCheck(
                      item.attr_id,
                      item.attr_name,
                      subItem.attr_value_name,
                      '',
                      subItem.attr_value_id,
                    ) === 0 || isAllSoldOut
                      ? 'product-intro__size-radio_soldout'
                      : '',
                    chooseCheck(
                      item.attr_id,
                      item.attr_name,
                      subItem.attr_value_name,
                      '',
                      subItem.attr_value_id,
                    ) === undefined
                      ? 'product-intro__size-radio_disabled'
                      : '',
                  ]"
                  :da-event-click="eventClickId"
                  :data-size-radio="subItem.attr_value_id"
                  :data-attr_value_name="subItem.attr_value_name"
                  :data-attr_id="item.attr_id"
                  tabindex="0"
                  :aria-label="calcSize(subItem, item.attr_id).text"
                  @touchstart="handleChooseTouch($event, true, item, subItem)"
                  @click="handleChoose($event, true, item, subItem)"
                >
                  <p
                    class="product-intro__size-radio-inner"
                    :class="{
                      'product-intro__sizes-item-text--one': !isLongSizeTwoLine(calcSize(subItem, item.attr_id).text),
                      'product-intro__sizes-item-text--two': isLongSizeTwoLine(calcSize(subItem, item.attr_id).text)
                    }"
                    v-html="calcSize(subItem, item.attr_id, config?.shouldWeakenDefaultSize).text"
                  >
                  </p>
                </div>
              </template>
              <AttrPicInfo
                v-if="showAttrImgPopover && subItem.attr_image"
                :text="calcSize(subItem, item.attr_id).text"
                :image="subItem.attr_image"
              />
              <div
                v-else-if="subItem.size_desc && subItem.size_desc.length"
                class="product-intro__size-popover-content"
              >
                <!-- 尺寸标题 -->
                <div
                  v-if="!oneLocalSizeDesc(subItem,item.attr_id)"
                  class="product-intro__size-popover-text"
                >
                  {{ sizeInfoTitle }}
                </div>
                <div 
                  class="product-intro__size-popover-desc"
                  v-html="oneLocalSizeDesc(subItem,item.attr_id)"
                ></div>
                <ul
                  v-for="desc in subItem.size_desc"
                  :key="desc.name"
                  class="product-intro__size-popover-list"
                  :class="{
                    morethan3: subItem.size_desc.length > 3,
                    lessthan3:
                      (subItem.size_desc.length > 1 &&
                        subItem.size_desc.length <= 3) ||
                      (subItem.size_desc.length == 1 &&
                        subItem.size_desc[0].name),
                  }"
                >
                  <li
                    v-if="desc.name && subItem.size_desc.length > 1"
                    class="product-intro__size-popover-title"
                  >
                    {{ desc.name }} -
                  </li>
                  <li
                    v-for="subDesc in desc.list"
                    :key="subDesc.k"
                    class="product-intro__size-popover-li"
                  >
                    {{ subDesc.k }}:
                    <span>{{ subDesc.val }}</span>
                  </li>
                </ul>
                <div
                  v-show="showDetail"
                  class="product-intro__size-popover-detail"
                  @click="sizeGuideModalShow(undefined, { fromScene: 'cart_pop' })"
                >
                  {{ language.SHEIN_KEY_PC_15098 }}
                </div>
              </div>
            </s-popover>
          </template>
          <!-- sizegourp融入size -->
          <template
            v-if="showSizeGroupAfterSize && item.attr_id == 87"
          >
            <div
              v-for="(sizeGroupItem, sizeGroupIndex) in onlyRelatedGoods"
              :key="sizeGroupIndex"
              v-expose="getSizegourpAnalysisData({ type: 'expose', sizeGroupItem })"
              v-tap="getSizegourpAnalysisData({ type: 'click', sizeGroupItem })"
              class="product-intro__size-radio"
              tabindex="0"
              @click="showQuickviewModal($event, true, sizeGroupItem,undefined, 'sizegroup')"
            >
              <div class="product-intro__size-radio-inner">
                <span>{{ sizeGroupItem.displayDesc }}</span>
                <Icon 
                  name="sui_icon_more_right_14px_1" 
                  size="14px"
                  :is-rotate="GB_cssRight" 
                  :custom-style="{ verticalAlign: '-3px'}"
                />
              </div>
            </div>
          </template>
          <!-- 当前商品存在关联大码 -->
          <s-popover
            v-if="relateGoodType === 1 && item.attr_id == 87 && !sizeGroupPosition && !hideKidRelatedSize && !config.hideRelationPlusSize && !hideSizeRange"
            ref="popoverRef"
            :key="999"
            :class="[
              'relate-good',
              'product-intro__size-radio-spopover',
              `j-product-intro__size-radio-spopover_${item.attr_id}_index999`,
            ]"
            :placemen="sizePopoverPlacemen[999]"
            trigger="hover"
            :append-to-body="true"
            :empty-un-show="true"
          >
            <template #reference>
              <div
                v-enterkey
                class="product-intro__size-radio"
                tabindex="0"
                @touchstart="showQuickviewModalTouch($event, true, relateGood)"
                @click="showQuickviewModal($event, true, relateGood)"
              >
                <div class="product-intro__size-radio-inner">
                  <span
                    v-for="(subItem, i) in relateGood.sizeText"
                    :key="i"
                    v-html="getRelateSize(subItem)"
                  ></span>
                  <Icon
                    :class="{ 'to-right': from == 'detail' }"
                    name="sui_icon_paly_40px"
                    size="14px"
                    :is-rotate="GB_cssRight"
                    :custom-style="{ verticalAlign: '-2px'}"
                  />
                </div>
              </div>
            </template>
            <div class="product-intro__size-popover-content">
              <div
                class="product-intro__size-popover-img"
                :style="{
                  backgroundImage: `url(${relateGood.goods_thumb})`,
                }"
              ></div>
              <div class="product-intro__size-popover-wrap">
                <div class="product-intro__size-popover-stitle">
                  {{ language.SHEIN_KEY_PC_17359 }}
                </div>
                <div class="product-intro__size-popover-gtitle">
                  {{ relateGoodsName }}
                </div>
                <div class="product-intro__size-popover-gprice">
                  <ProductCardPriceSimpleAutoComplianceMode 
                    :goods-info="relateGood"
                    :is-paid="productIntroData.isPaidUser"
                    :language="language"
                    :config="{
                      showS3VipPriceOnSale: config?.isS3newPrice
                    }"
                  />
                </div>
              </div>
            </div>
          </s-popover>
        </div>
        <div
          v-if="foldBtn[index]"
          class="product-intro__sizes-more"
          @click="handleOpenFold"
        >
          {{ language.SHEIN_KEY_PC_26830 || 'View All' }}
          <Icon
            name="sui_icon_more_down_12px_2"
            size="12px"
          />
        </div>
      </div>
    </div>
  </ClientOnly>

  <ClientOnly>
    <!-- Size Radio End -->
    <SizeGroup
      v-if="showSizeGroupAfterSizeUndertake"
      :goods-id="goodsId"
      :from="from"
      :language="language"
      :only-related-goods="onlyRelatedGoods"
      :after-size-scene="true"
      @handle-click-size-group-item="handleClickSizeGroupItem"
    />
    <SizeGroup
      v-if="sizeGroupPosition === 'bottom'"
      :goods-id="goodsId"
      :from="from"
      :language="language"
      :ccc-attr-groups="cccAttrGroups"
      :constant="{
        complianceTipsMode: complianceTipsMode,
        complianceMode: complianceMode,
        isHitComplianceMode: isHitComplianceMode,
      }"
      @handle-click-size-group-item="handleClickSizeGroupItem"
    />
  </ClientOnly>
  <!-- SIZE OUTER -->
  <div
    v-if="
      sizeInfoIndex[sizeSaleAttrId] &&
        sizeInfoIndex[sizeSaleAttrId].attr_value_id &&
        sizeInfoAtOuter
    "
    class="product-intro__size-outer"
  >
    <div class="product-intro__size-popover-text">
      {{ sizeInfoTitle }}
    </div>
    <ul
      v-for="desc in sizeInfoIndex[sizeSaleAttrId].size_desc"
      :key="desc.name"
      class="product-intro__size-popover-list"
      :class="{
        morethan3: sizeInfoIndex[sizeSaleAttrId].size_desc.length > 3,
        lessthan3:
          (sizeInfoIndex[sizeSaleAttrId].size_desc.length > 1 &&
            sizeInfoIndex[sizeSaleAttrId].size_desc.length <= 3) ||
          (sizeInfoIndex[sizeSaleAttrId].size_desc.length == 1 &&
            sizeInfoIndex[sizeSaleAttrId].size_desc[0].name),
      }"
    >
      <li
        v-if="
          desc.name && sizeInfoIndex[sizeSaleAttrId].size_desc.length > 1
        "
        class="product-intro__size-popover-title"
      >
        {{ desc.name }} -
      </li>
      <li
        v-for="subDesc in desc.list"
        :key="subDesc.k"
        class="product-intro__size-popover-li"
      >
        {{ subDesc.k }}:
        <span>{{ subDesc.val }}</span>
      </li>
    </ul>
  </div>
  <SizeGroup 
    v-if="sizeGroupPosition === 'fold'"
    :from="from"
    :goods-id="goodsId"
    :language="language"
    :only-related-goods="onlyRelatedGoods"
    :is-paid-user="productIntroData.isPaidUser"
    :find-perfect-fit-scene="true"
    :constant="{
      complianceTipsMode: complianceTipsMode,
      complianceMode: complianceMode,
      isHitComplianceMode: isHitComplianceMode,
    }"
    @handle-click-size-group-item="handleClickSizeGroupItem"
  /> 
  <ProductFindSrimilarFloatContent
    v-if="(from === 'detail' || from === 'quickAdd') && findSimilarFloatInfo && findSimilarFloatInfo.show && findSimilarFloatInfo.mode === 'module'"
    v-bind="findSimilarBind"
  />

  <div>
    <!-- pageComponents.qtyConf.isShow为true时，库存提示由数量加减器显示 -->
    <div
      v-if="sizeTypeMap['priceTips']"
      class="product-intro__size-tips"
    >
      <span 
        v-if="sizeStockTips" 
        v-expose="{
          id: '1-8-6-239',
          data: {
            goods_level: currentMall?.sku_large_ship !== undefined ? 'sku' : 'skc',
            label_type: currentMall?.is_absolute_low_inventory ? 1 : 2,
            location: 'attr',
          },
        }" 
      >{{ soldOutTips }}&nbsp;&nbsp;</span>
      <span
        v-if="hasSizeData && flashSaleTip"
        style="color: #999"
      >{{ flashSaleTip }}</span>
      <span
        v-if="hasSizeData && underPriceTip"
        style="color: #999"
      >{{ underPriceTip }}</span>
    </div>

    <!-- SizeTips -->
    <span
      v-if="sizeTypeMap['sizeTips']"
      class="product-intro__size-deviation product-intro__size-deviation_below"
      @click="openSizeGuideFromSizeTips"
    >
      {{ sizeDeviationTips }}
      <span
        v-if="showHeelHeight"
        dir="ltr"
      >{{ showHeelHeight ? cmInchInfo : '' }}</span>
      <i
        v-show="isShowSizeTipsText"
        v-expose="getShowSizeTipsTextAnalysis({id: '1-8-6-136'})"
        class="product-intro__size-deviation-icon svgicon svgicon-arrow-down right"
      ></i>
      <span
        v-if="isShowTrueToSizeStrong"
        v-expose="getShowSizeTipsTextAnalysis({ id: '1-8-6-136', isStrong: true })"
        class="product-intro__undersize-deviation-tipsstrong"
        :class="{'product-intro__start-line': !!sizeDeviationTips,'cursor-pointer':isShowTrueToSizeStrong && from === 'detail'}"
        @click.stop.prevent="openReviews"
      >
        <span class="tipsstrong-span">
          <Icon
            class="like-icon"
            name="sui_icon_like_18px" 
            size="16px"
            :custom-style="{ verticalAlign: 'text-bottom'}"
          />{{ isShowSizeTips.true_size_text }}
        </span>
        {{ isShowSizeTips.showTrueToSizeStrongText }}
        <Icon
          v-if="from == 'detail'" 
          class="more-icon"
          name="icon-arrownew-left"
        />
      </span>
    </span>

    <!-- SIZE GUIDE -->
    <div
      v-if="!hideOneSize && hasSizeData && sizeTypeMap['sizeGuide']"
      class="product-intro__size-help"
    >
      <div
        v-if="sizeGuideShow && !sizeGuideInTitle"
        v-tap="{ id: '1-8-6-6', data: { goodsId: productIntroData.detail?.goods_id, from: 'other' }}"
        v-expose="{ id: '1-8-6-139', data: { goodsId: productIntroData.detail?.goods_id, from: 'other' }}"
        class="product-intro__size-guide-old"
        @click="sizeGuideModalShow(undefined, { fromScene: 'cart_title' })"
      >
        <Icon
          name="sui_icon_sizeguide_24px"
          size="24px"
        />
        <span class="product-intro__size-guide-old-t">
          {{
            language.SHEIN_KEY_PC_16557
          }}
        </span>
      </div>
      <a
        v-if="(sizeRecommendType==1 && showRecommend) && !isOneSize"
        class="product-intro__size-rec"
        @click="editMySize"
      >
        <span class="button-text">
          <template v-if="recommendMySize">
            {{ language.SHEIN_KEY_PC_19348 }}
            <b>{{ recommendMySizeText }}</b>
          </template>
          <template v-else>{{ language.SHEIN_KEY_PC_19355 }}</template>
        </span>
      </a>
    </div>
  </div>

  <ClientOnly>
    <template v-if="skcSaleAttr.length == 0">
      <div
        v-if="flashSaleTip || underPriceTip"
        class="product-intro__size-tips"
      >
        <span
          v-if="flashSaleTip"
          style="color: #999"
        >{{ flashSaleTip }}</span>
        <span
          v-if="underPriceTip"
          style="color: #999"
        >{{ underPriceTip }}</span>
      </div>
    </template>
  </ClientOnly>

  <template v-if="from == 'detail' && showSizeFeedBack">
    <ProductIntroSizeFeedback
      :language="language"
      :country="currentSelectedCountry"
      :current-size-list="noCalPolyAttrSize"
      :feedback-basic-sizes="feedbackBasicSizes"
      :is-new-size-local="isNewSizeLocal"
      :is-rw="isRw"
      :product-intro-data="productIntroData"
      @change-country="onChangeFeedBackCountry"
    />
  </template>

  <ProductIntroMall
    v-if="showMallSite && productIntroData.detail.mall_info.length > 0"
    :detail="baseInfo"
    :language="language"
    :mall-code="mallCode"
    :mall-stocks="mallStocks"
    :site-uid="siteUid"
    :from="from"
    :is-on-sale="!isAllSoldOut"
    :choose-check="chooseCheck"
    :config="config"
    @mall-click="mallClick"
  />

  <ClientOnly>
    <ProductIntroQuickShip
      v-if="showQuickShipStatus"
      :from="from"
      :new-open="newOpen"
      :mall-code="mallCode"
      :sku-info="skuInfo"
      :sku-list="skuList"
      :skc-sale-attr="skcSaleAttr"
      :language="language"
      :is-new-size-local="isNewSizeLocal"
      :delivery-floor-quickship-title="deliveryFloorQuickshipTitle"
      :is-jp-quick-ship="isJpQuickShip"
      :hidden-partial-quick-ship="config.hiddenPartialQuickShip"
      @update-quick-ship="handleUpdateQuickShip"
    />
  </ClientOnly>

  <!-- POP -->
  <ClientOnly>
    <ProductSizeGuid
      v-if="renderSizeGuideModal"
      v-model:value="sizeGuideModal"
      v-model:current-unit="currentSizeUnit"
      :language="language"
      :is-rw="isRw"
      :product-intro-data="productIntroData"
      :sale-attr-list="saleAttrList"
      :country="currentSelectedCountry"
      :has-local-size="hasLocalSize"
      :poly-attr-size="polyAttrSize"
      :is-new-size-local="isNewSizeLocal"
      :size-priority="'off'"
      :size-guide-fit="sizeGuideFit"
      :tsp-show-buy-size-table="tspShowBuySizeTable"
      @change-select-country="onChangeSelectCountry"
    />
    <SizeRecommend
      v-model:value="showSizeRecommend"
      :unit="currentSizeUnit"
      :language="language"
      :product-intro-data="productIntroData"
      @fresh-recommend-size="initRecommendSize"
      @size-guide-modal-show="sizeGuideModalShow"
    />
    <SizeBraRecommend
      v-model:value="showBraSizeRecommend"
      :unit="currentSizeUnit"
      :language="language"
      :product-intro-data="productIntroData"
      :sale-attr-list="saleAttrList"
      :related-good-sizes="relatedGoodSizes"
      @fresh-recommend-size="initRecommendSize"
      @size-guide-modal-show="sizeGuideModalShow"
      @set-good-detail-from="setGoodDetailFrom"
    />
  </ClientOnly>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import SizeGroup from './Size/components/SizeGroup'
import ProductCardPriceSimpleAutoComplianceMode from 'public/src/pages/goods_detail_v2/components/ProductCardPriceSimpleAutoComplianceMode.vue'

// import sizeGroupConfig from './Size/components/SizeGroup/sizeGroupLanguageMapConfig'
import ProductFindSrimilarFloat from './ProductFindSrimilarFloat/index'
import ProductFindSrimilarFloatContent from './ProductFindSrimilarFloat/ProductFindSrimilarFloatContent.vue'
import ProductIntroSizeFeedback from './ProductIntroSizeFeedback'
import ProductIntroMall from './ProductIntroMall'
import ProductIntroQuickShip from './ProductIntroQuickShip'
import SizeRecommend from './SizeRecommend.vue'
import SizeBraRecommend from './SizeBraRecommend'
import CommonLocalSizeSelect from './CommonLocalSizeSelect'
import ProductIntroSizeSkeleton from './ProductIntroSizeSkeleton'
import { getBubbleInfo, isBaseCodePriorityComplete } from '../utils/sizeInfoDesc'
import eventBus from '../utils/eventBus'
import { abtservice } from 'public/src/services/abt'
import { isMobile as isPhone, template, asyncLoadFile, debounce } from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
import schttp from 'public/src/services/schttp'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import AttrPicInfo from 'public/src/pages/goods_detail_v2/components/Size/components/AttrPicInfo/index.vue'
import sizeGuideFromSceneMap from '../utils/sizeGuideFromSceneMap'
import { OriginSizes } from '../constants'
import { sortMainSubSize } from 'public/src/pages/goods_detail_v2/utils/sizeInfoDesc.js'
import { genStockTips } from 'public/src/pages/goods_detail_v2/utils/inventory.js'
import { getAbSizeGroup } from 'public/src/pages/goods_detail_v2/components/Size/utils/index.js'

let daEventExpose = daEventCenter.getExposeInstance()
const { GB_cssRight = false, SiteUID } = gbCommonInfo || {}
daEventCenter.addSubscriber({ modulecode: '1-8-6' })


/**
 * 将DetailInfo接口中的二级销售属性的图片信息补充到 skcSaleAttr 中
 * @param {*} skcSaleAttr 
 * @param {*} secondSaleAttributes 
 */
const formatSkcSaleAttrPatchPicInfo = (skcSaleAttr = [], secondSaleAttributes = []) => {
  if (!secondSaleAttributes?.length) return skcSaleAttr

  const secondSaleAttributesMap = secondSaleAttributes.reduce((map, d) => {
    if (!d?.attr_id || !d?.attr_value_list?.length) return map
    map[d.attr_id] = d.attr_value_list.reduce((subMap, subItem) => {
      subMap[subItem.attr_value_id] = subItem
      return subMap
    }, {})
    return map

  }, {})

  return skcSaleAttr.map(d => {

    const _picInfoMap = secondSaleAttributesMap[d?.attr_id]
    if (!_picInfoMap || !d?.attr_value_list?.length) return d

    return {
      ...d,
      attr_value_list: d.attr_value_list.map(subItem => {
        return {
          ...subItem,
          attr_desc: _picInfoMap[subItem.attr_value_id]?.attr_desc || '',
          attr_image: _picInfoMap[subItem.attr_value_id]?.attr_image || '',
        }
      })
    }
  })
}


export default {
  name: 'ProductIntroSizeV3',
  components: {
    SizeGroup,
    ProductCardPriceSimpleAutoComplianceMode,
    ProductFindSrimilarFloatContent,
    ProductIntroSizeSkeleton,
    ProductIntroSizeFeedback,
    ProductIntroMall,
    ProductIntroQuickShip,
    ProductSizeGuid: defineAsyncComponent(() => import(/* webpackChunkName: "ProductSizeGuid" */'./ProductSizeGuid')),
    SizeRecommend,
    SizeBraRecommend,
    CommonLocalSizeSelect,
    ClientOnly,
    Icon,
    AttrPicInfo,
  },
  props: {
    isRw: {
      type: Boolean,
      default: false
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    realtimeBffInfo: {
      type: Object,
      default: () => ({})
    },
    tspShowBuySizeTable: {
      type: Boolean,
      default: false
    },
    langPath: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: () => ({})
    },
    findSimilarFloatInfo: {
      type: Object,
      default: () => ({})
    },
    from: {
      type: String,
      default: ''
    },
    showQuickShip: {
      type: Boolean,
      default: false
    },
    eventClickId: {
      type: String,
      default: ''
    },
    productIntroData: {
      type: Object,
      default: () => ({})
    },
    newFlashPromotion: {
      type: Object,
      default: () => ({})
    },
    skuNoStock: {
      type: Object,
      default: () => ({})
    },
    currentUnit: {
      type: Number,
      default: -1
    },
    checkNotSelectedTips: {
      type: [Number, Boolean],
      default: 0
    },
    language: {
      type: Object,
      default: () => ({})
    },
    country: {
      type: String,
      default: ''
    },
    cccConfig: { // TODO 不要放组件内部
      type: Object,
      default: () => ({})
    },
    pageComponents: {
      type: Object,
      default: () => ({ sizeGuideConf: {}, qtyConf: {} })
    },
    sizeInfoAtOuter: {
      type: Boolean,
      default: false
    },
    sizeGuideInTitle: {
      type: Boolean,
      default: false
    },
    externalSizeInfoIndex: {
      // 初始化传入选中的sizeInfoIndex, 优先级更高
      type: Object,
      default: () => ({})
    },
    externalSkuCode: {
      // 初始化传入选中的sku，只在初始化时执行，和externalSizeInfoIndex二选一传入
      type: String,
      default: ''
    },
    newOpen: {
      type: Boolean,
      default: false
    },
    showRecommend: {
      type: Boolean,
      default: false
    },
    isHiddenPlusSize: {
      type: Boolean,
      default: false
    },
    showHeelHeight: {
      type: Boolean,
      default: false
    },
    heelHeight: {
      type: String,
      default: ''
    },
    cmInchInfo: {
      type: String,
      default: ''
    },
    relatedGoodSizes: {
      type: Object,
      default: () => ({})
    },
    isNewSizeLocal: {
      // 本地尺码新逻辑
      type: Boolean,
      default: false
    },
    hideMallSite: {
      type: Boolean,
      default: false
    },
    isShowSizeTips: {
      type: Object,
      default: () => ({})
    },
    showSizeFeedBack: {
      type: Boolean,
      default: false
    },
    siteUid: {
      type: String,
      default: ''
    },
    showAttrImgPopover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      GB_cssRight,
      sizePopoverPlacemen: [],
      showSizeRecommend: false,
      showBraSizeRecommend: false, //是否展示是内衣的尺码自有推荐
      renderSizeGuideModal: false,
      sizeGuideModal: false,
      relateGood: null, // 关联的大码/普通码商品数据
      quickviewModal: {
        // 快速加车弹窗数据
        index: 10
        // openEvent: { // 快速加车弹窗组件需要的数据
        //     status: {
        //         open: false,
        //     },
        //     pageType: '详情页',
        // },
      },
      showEditSizeWhenClose: false,
      userSelectSize: false,
      sizeRecommendType: 0,
      recommendMySize: null,
      isFromRecommend: false,
      notSelectedTipsAnim: false,
      notSelectedTips: [],
      tackInfoDes: [], //家居品类额外描述
      saleAttrList: {
        skc_name: '',
        skc_sale_attr: [],
        sku_list: []
      },
      isNewGoods: true,
      needSetSkuInfo: '',
      needSetSizeIndex: {},
      firstCompose: true,
      sizeInfoIndex: {},
      sizeInfoIndexBak: '{}',
      skuInfo: {},
      dataMapOrder: [], // dataMap字符串的顺序
      dataMap: {},
      skuMap: {},
      dataIdMap: {},
      skuIdMap: {},
      showDetail: false,
      plusSizeRule: {},
      currentSelectedCountry: '',
      isComplete: false,
      isChildSizeExpose: false,
      foldBtn: [],
      foldIndex: -1,
    }
  },
  computed: {
    currentTabLocalIsActive() {
      if(this.currentSelectedCountry){
        return this.currentSelectedCountry === this.config?.localSizeList?.country_code
      }
      let lastSelectCountry = window.localStorage.getItem('last_select_country')
      if(lastSelectCountry && lastSelectCountry != this.config?.localSizeList?.country_code ){
        return false
      }
      if (!lastSelectCountry && ['old', 'local_size_no_default'].includes(this.config?.defaultlocalsize)) {
        return true
      }
      return false
    },
    mallStocks() {
      if (this.skuInfo?.sku_code) {
        // sku维度的mall
        return this.skuInfo?.mall_stock || []
      }
      return []
    },
    feedbackBasicSizes() {
      const { currentSizeList } = this
      if (!currentSizeList.length) {
        return []
      }
      const one = currentSizeList[0].attr_value_name
      for (let i = 0; i < OriginSizes.length; i++) {
        if (OriginSizes[i].includes(one)) {
          return OriginSizes[i]
        }
      }
      return []
    },
    exposeAnalysisData() {
      if(!this.isNewGoods && this.skuList && this.skuList.length){
        const haveLocal = this.skcSaleAttr?.some(item => item.attr_id == 87)
        return {
          id: '1-8-6-25',
          data: {
            if_have_localsize_entry: this.isNewSizeLocal && this.hasMultiLocal && haveLocal && !this.index ? 1 : 0,
            localsize_style_type: this.config.showOnlyOneLocalSize ? 'tab' : 'list',
            localsize_choose_type: !!this.country ? 1 : 0,
            if_have_feedback_entry: this.from == 'detail' && this.showSizeFeedBack && this.feedbackBasicSizes.length ?  1 : 0,
            count: this.skcSaleAttr.length
          },
          prefix: 'expose_goods_detail_select_otherattr_code'
        }
      }
      return {
        id: '',
      }
    },
    baseInfo() {
      const baseInfo = { ...this.productIntroData?.detail }
      baseInfo.mall_info.forEach(item => {
        const siteMall = this.productIntroData?.siteMallInfo?.find?.(site => site.mall_code === item.mall_code)
        // 混入mall tag，用于判断是本地仓还是跨境仓，最后拿来上报
        if (siteMall) {
          item.mall_tags = siteMall.mall_tags
          item.mall_sort = siteMall.mall_sort
        }
      })
      return baseInfo
    },
    sizePriority() {
      return 'off'
    },
    sizeGuideFit() {
      return this.productIntroData?.screenAbtConfig?.sizeguidefit?.param?.sizeguidefit || 'off'
    },
    openAutoCheckMall() {
      return this.productIntroData?.screenAbtConfig?.mallchange?.param?.mallshow === 'auto'
    },
    openIdMap() {
      return !!(typeof gbCommonInfo !== 'undefined' && gbCommonInfo?.OPEN_DETAIL_SIZE_IDMAP)
    },
    goodsId() {
      return this.productIntroData?.detail?.goods_id
    },
    complianceMode() {
      return this.config.complianceMode || false
    },
    complianceTipsMode() {
      return this.config.complianceTipsMode || false
    },
    isHitComplianceMode() {
      return this.config.isHitComplianceMode || false
    },
    sizeTypeMap () {
      const { 
        sizeStockTips, 
        flashSaleTip, 
        underPriceTip, 
        pageComponents, 
        sizeGuideShow, 
        sizeGuideInTitle, 
        sizeRecommendType,
        sizeDeviationTips,
        from,
        isShowSizeTipsText,
        isShowTrueToSizeStrong
      } = this
      return {
        priceTips: !!(sizeStockTips || flashSaleTip || underPriceTip),
        sizeTips: (sizeDeviationTips && from == 'detail') || isShowSizeTipsText || isShowTrueToSizeStrong,
        sizeGuide: pageComponents.sizeGuideConf.isShow !== false && ((sizeGuideShow && !sizeGuideInTitle) || sizeRecommendType == 1)
      }
    },
    findSimilarBind() {
      return Object.assign({
        isQuickAdd: this.from === 'quickAdd',
        language: this.language,
        skuInfo: this.skuInfo,
        productIntroData: this.productIntroData,
        update: this.findSimilarFloatInfo?.show || 0,
        updateExposedList: this.updateExposedList,
        autoGetCouponAtAddBag: this.autoGetCouponAtAddBag
      }, this.findSimilarFloatInfo || {})
    },
    isMobile() {
      if (typeof window !== 'undefined') {
        return isPhone()
      }
      return false
    },
    mallCode() {
      return this.productIntroData.mallCode
    },
    currentMall() {
      let currentMall = {}
      if (this.skuInfo?.sku_code) {
        // sku维度的mall
        currentMall = this.skuInfo.mall_stock?.find(item => item.mall_code === this.mallCode) || {}
        currentMall.can_sale_days = currentMall.sku_can_sale_days
      } else {
        // skc维度的mall
        currentMall = this.productIntroData?.detail?.mall_stock?.find(item => item.mall_code === this.mallCode) || {}
        currentMall.can_sale_days = currentMall.skc_can_sale_days
      }
      return currentMall
    },
    showMallSite() {
      const { hideMallSite, productIntroData } = this
      return !hideMallSite && productIntroData.showMallSite
    },
    currSkuInfoStock() {
      return this.skuInfo?.mall?.[this.mallCode]?.stock || 0
    },
    isAllSoldOut() {
      return (
        Number(this.productIntroData?.detail?.is_on_sale) === 0 ||
        Number(this.productIntroData?.detail?.stock) === 0
      )
    },
    hasLocalSize() {
      if (!this.localSizeRule.length) {
        return false
      } else {
        for (const attrSizeItem of this.polyAttrSize) {
          for (const localSizeItem of this.localSizeRule) {
            if (attrSizeItem.attr_value_name_en === localSizeItem.name) {
              return true
            }
          }
        }
        return false
      }
    },
    currentGoodsId() {
      return this.productIntroData?.detail?.goods_id || ''
    },
    sizeSaleAttrId() {
      return this.bubbleInfo.sizeInfoDesAttrId || 87
    },
    sizeRelatedTest() {
      if (this.productIntroData?.tsp?.[100000317] == '1') {
        return this.productIntroData?.screenAbtConfig?.goodsSizeRelated?.param?.sizerelatedtest || 'only_manual'
      }
      return ''
    },
    hideSizeRange() {
      if (this.sizeRelatedTest) {
        const series_info_list = this.relateGood?.series_info_list || []
        const isManualGroup = series_info_list?.some?.(i => i.theme_id == 543 || i.theme_id == 3)
        if (this.sizeRelatedTest === 'all_on') return false
        if (this.sizeRelatedTest === 'all_off') return true
        if (this.sizeRelatedTest === 'only_cv') {
          // 仅展示cv场景下，隐藏人工搭配
          return isManualGroup
        }
        return !isManualGroup
      }
      return false
    },
    cccAttrGroups(){
      if (this.sizeRelatedTest) {
        return getAbSizeGroup(this?.sizeRelatedTest, this.productIntroData?.cccAttrGroups?.allSizeGroups || [])
      }
      return this.productIntroData?.cccAttrGroups?.cccList || []
    },
    onlyRelatedGoods(){
      if (this.sizeRelatedTest) {
        return this.cccAttrGroups?.filter?.(item => !item.isCurrentGroup) || []
      }
      return this.productIntroData?.cccAttrGroups?.onlyRelatedGoods || []
    },
    // quickship强化 通用文案  可供size quickship tips使用
    deliveryFloorQuickshipTitle() {
      let quickShipContent = this.realtimeBffInfo?.shipInfo?.quickShipContent || ''
      // 这里前端不再判断abt，直接读quickShipContent
      return quickShipContent 
    },
    isJpQuickShip (){
      let quickShipContent = this.realtimeBffInfo?.shipInfo?.quickShipContent || ''
      const quickShipTimeType = this.realtimeBffInfo?.shipInfo?.quickShipTimeType || '0'
      let { Quickshipdetailbannershow = '', Quickshipdetailfloorshow = '' }  = this.productIntroData?.screenAbtConfig?.quickshipdetailshow?.p || {}
      const beltQuickshipIndeArr = Quickshipdetailbannershow.split('_index')
      const beltQuickshipcoexArr = Quickshipdetailbannershow.split('_coex')
      let beltQuickshipNCoex = false, beltQuickshipNindex = false
      if(beltQuickshipIndeArr.length > 1) {
        beltQuickshipNindex = true
      }
      if(beltQuickshipcoexArr.length > 1) {
        beltQuickshipNCoex = true
      }
      const beltNDeliveryStyle = beltQuickshipNindex || beltQuickshipNCoex
      const floorNDeliveryStyle = +(Quickshipdetailfloorshow) > 0 // 物流楼层n天达
      return !!(SiteUID === 'jp' && (beltNDeliveryStyle || floorNDeliveryStyle)  && quickShipContent) || ['3', '4'].includes(quickShipTimeType)
    },
    // priceCardConfig() {
    //   let config = {
    //     camelCasePriceMaxSize: 17,
    //     camelCasePriceMinSize: 11,
    //     direction: 'column',
    //   }
    //   if(this.complianceTipsMode) {
    //     config.priceColor = '#000'
    //     config.hidePriceBottomLabel = true
    //   }
    //   return config
    // },
    // sizeGroupList() {
    //   const currentGoods = this.productIntroData?.detail || {} 
    //   const relateGoods = this.productIntroData?.getOtherOptions || []
    //   const relateGoodsForGroup = relateGoods.filter(i => Number(i.theme_type) === 3)
    //   const language = this.language

    //   const originalSizeGroupList = [currentGoods, ...relateGoodsForGroup].map((item, index) => ({
    //     goodsId: item.goods_id,
    //     catId: item.cat_id,
    //     goodsName: item.goods_name,
    //     goodsSN: item.goods_sn,
    //     isCurrentGroup: index === 0
    //   }))

    //   const sizeGroupList = []

    //   sizeGroupConfig.sizeGroupSortRule.forEach(item => {
    //     const catIdSet = sizeGroupConfig[item]
    //     originalSizeGroupList.forEach(sizeGroupItem => {
    //       const sizeGroupLanguageMap = sizeGroupConfig.sizeGroupLanguageMap
    //       const curSizeGroupLanguage = language[sizeGroupLanguageMap[item]]
    //       if (catIdSet.includes(sizeGroupItem.catId) && curSizeGroupLanguage) {
    //         sizeGroupList.push({
    //           ...sizeGroupItem,
    //           languageKey: sizeGroupLanguageMap[item],
    //           displayDesc: curSizeGroupLanguage
    //         })
    //       }
    //     })
    //   })
    //   return sizeGroupList.slice(0, 2) // sizeGroup最多展示两个
    // },
    // 隐藏童装大小码
    hideKidRelatedSize () {
      return this.productIntroData?.tsp?.[100000264] == '1' || this.productIntroData?.tsp?.[100000303] == '1'
    },
    sizeGroupPosition() {
      const goodsSizeGroupRelatedValue = this.productIntroData?.screenAbtTspConfig?.goodsSizeGroupRelated?.param?.goodsSizeGroupRelated
      const showSizeGroupPosition = ['mid', 'bottom', 'fold', 'size'].includes(goodsSizeGroupRelatedValue)  && !this.hideKidRelatedSize && goodsSizeGroupRelatedValue
      // 关联商品不包含主商品
      if(showSizeGroupPosition === 'size') return this.onlyRelatedGoods.length && !this.config.hideSizeGroupeSize ? showSizeGroupPosition : ''
      if(showSizeGroupPosition === 'fold') return this.onlyRelatedGoods.length && !this.config.hideRelationGoods ? showSizeGroupPosition : ''
      const curSizeGroupIndex = this.cccAttrGroups?.findIndex((i) => i.isCurrentGroup)
      if(this.cccAttrGroups.length < 2 || !showSizeGroupPosition || curSizeGroupIndex === -1) return void 0
      return showSizeGroupPosition
    },
    showSizeGroupAfterSize() {
      if(this.sizeGroupPosition !== 'size' || !(this.hasSizeData && !this.hideOneSize)) return false
      return true
    },
    showSizeGroupAfterSizeUndertake() {
      if(this.sizeGroupPosition !== 'size' || (this.hasSizeData && !this.hideOneSize)) return false
      return true
    },
    /**
     * 是否商品id对应的尺码有数据
     */
    hasSizeData() {
      const { sizeSaleAttrId } = this
      const status = !!(this.noCalAttrList || []).find(_ => _.attr_id == sizeSaleAttrId)
      return status
    },
    skcSaleAttr() {
      let skcSaleAttr = this.saleAttrList.skc_sale_attr
      const sizeListIndex = skcSaleAttr.findIndex(
        _ => _.attr_id == this.sizeSaleAttrId
      )
      if (sizeListIndex !== -1) {
        const sizeList = skcSaleAttr[sizeListIndex]
        sizeList.attr_value_list = sizeList.attr_value_list.map(
          (attr, index) => {
            const sizeRule = this.localSizeRule.find(
              item =>
                item.name == attr.attr_value_name_en &&
                attr.attr_value_name_en != 'one-size'
            )
            if (sizeRule?.correspond?.length) {
              attr.correspond = sizeRule.correspond
            } else {
              attr.correspond = ''
            }
            const result = []
            const info =
              this.bubbleInfo?.[this.currentSizeUnit == 1 ? 'inch' : 'cm'] ||
              []
            info.forEach(_ => {
              const item = _.list[index]
              const list = []
              for (const k in item) {
                if (this.sizeInfoStrFilterField.includes(k)) continue
                const val = item[k].replace(/(cm|inch)/g, '').trim()
                if (val && val !== '/') {
                  list.push({ k: k.trim(), val: item[k].trim() })
                }
              }
              if (list.length) {
                result.push({
                  name: _.name,
                  list
                })
              }
            })

            // if ('this.isShowBaseCode') {
            if (this.isShowBaseCode) {
              let baseSizeSortMap = this.sizeInfoDes.basicAttribute.base_atte_sort || null
              if (baseSizeSortMap) {
                for (let item of result) {
                  item.list = item.list.sort((a, b) => baseSizeSortMap[a.k] - baseSizeSortMap[b.k])
                }
              }
            }
            attr.size_desc = result

            return attr
          }
        )
        skcSaleAttr.splice(sizeListIndex, 1)
        skcSaleAttr.push(sizeList)
      }
      this.$emit('handleData', {
        skcSaleAttr
      })
      return skcSaleAttr
    },
    skuList() {
      return this.saleAttrList.sku_list
    },
    polyAttrSize() {
      const polyAttrSize = this.saleAttrList.skc_sale_attr.find(
        _ => _.attr_id == this.sizeSaleAttrId
      )
      return polyAttrSize?.attr_value_list || []
    },
    // noCal 对skucode没有强依赖的数据，ssr 都用这个去渲染
    noCalAttrList() {
      const { detail = {}, attrSizeList = {} } = this.productIntroData
      const { sale_attr_list } = attrSizeList
      const goodsId = detail?.goods_id
      return sale_attr_list?.[goodsId]?.skc_sale_attr || []
    },
    noCalPolyAttrSize() {
      const polyAttrSize = this.noCalAttrList.find(
        _ => _.attr_id == this.sizeSaleAttrId
      )
      return polyAttrSize?.attr_value_list || []
    },
    currentSizeUnit: {
      get() {
        return this.currentUnit
      },
      set(val) {
        this.$emit('update:currentUnit', val)
      }
    },
    // 是否有内衣尺码 并且只在shein下展示
    recommendMySizeText() {
      if (this.productIntroData?.rule_type == 1) {
        let recommendMyBraSize = window.sessionStorage.getItem(
          'recommendMyBraSize'
        )
        return this.recommendMySize || recommendMyBraSize
      }
      if (!this.currentSelectedCountry) return this.recommendMySize
      const find =
        this.localSizeRule?.find(el => el.name == this.recommendMySize)
          ?.correspond || ''

      const { isNewSizeLocal } = this
      if (find) {
        if (isNewSizeLocal) {
          return `${this.currentSelectedCountry}${find}(${this.recommendMySize})`
        } else {
          return `${this.recommendMySize}(${this.currentSelectedCountry}${find})`
        }
      }
      return `${this.recommendMySize}`
    },
    // 国际尺码
    attrSizeList() {
      return this.productIntroData.attrSizeList || {}
    },
    // 本地尺码
    localSizeRule() {
      let country = this.currentSelectedCountry
      if(this.config.showOnlyOneLocalSize ){
        country = this.currentTabLocalIsActive ? this.config?.localSizeList?.country_code : ''
      } 
      return (
        this.productIntroData?.multiLocalSize?.size_rule_list?.[
          country
        ] || []
      )
    },
    multiLocalSize() {
      return (
        (this.productIntroData.multiLocalSize &&
          this.productIntroData.multiLocalSize.size_rule_list) ||
        {}
      )
    },
    localSizeMap(){
      let map = {}
      this.localSizeRule?.forEach?.((item) => {
        if (item.name == 'one-size') return
        map[item.name] = item.correspond
      })
      return map
    },
    hasMultiLocal() {
      return JSON.stringify(this.multiLocalSize) !== '{}'
    },
    // 尺寸描述信息
    sizeInfoDes() {
      return this.productIntroData.sizeInfoDes || {}
    },
    sizeInfoStrFilterField() {
      return [
        'size',
        'attr_id',
        'attr_name',
        'attr_value_id',
        'attr_value_name',
        'attr_value_name_en'
      ]
    },
    bubbleInfo() {
      return getBubbleInfo(
        this.noCalAttrList,
        this.sizeInfoDes,
        this.tackInfoDes,
        this.language
      )
    },
    isShowBaseCode () {
      return isBaseCodePriorityComplete(this.saleAttrList.skc_sale_attr, this.sizeInfoDes)
    },
    sizeInfoTitle() {
      return this.bubbleInfo.title
    },
    sizeGuideShow() {
      const bubbleInfo = this.bubbleInfo[
        this.currentSizeUnit == 1 ? 'inch' : 'cm'
      ]
      for (let i = 0; i < bubbleInfo.length; i++) {
        if (bubbleInfo[i].list.length) {
          for (let j = 0; j < bubbleInfo[i].list.length; j++) {
            const keys = Object.keys(bubbleInfo[i].list[j])
            if (
              keys.filter(_ => !this.sizeInfoStrFilterField.includes(_))
                .length > 0
            ) {
              return true
            }
          }
        }
      }
      return false
    },
    soldOutTipsLimitNum () {
      // 库存提示上限
      const { onlyxleft = {} } = this?.productIntroData?.screenAbtConfig || {}
      return onlyxleft?.p?.onlyxleft === 'ten' ? 10 : 5
    },
    // 所有sku库存紧张
    almostSoldOut() {
      let skuStock = 0
      for (let i = 0; i < this.skuList.length; i++) {
        skuStock += +this.skuList[i]?.mall?.[this.mallCode]?.stock || 0
        if (skuStock > this.soldOutTipsLimitNum) {
          return false
        }
      }
      if (skuStock == 0) {
        return false
      }
      return true
    },
    soldOutTips() {
      return genStockTips(
        this.currentMall, 
        this.productIntroData?.screenAbtConfig,
        this.productIntroData?.screenAbtTspConfig, 
        this.language
      )
    },
    sizeStockTips() {
      if (this.config.getSkcEstimatedInfo?.isSatisfiedBuyMultiple) return false
      if (this.config.qtySelectShow) return false
      let judge
      const { stock, is_absolute_low_inventory, is_relatively_low_inventory } = this.currentMall || {}
      if (stock <= 0) {
        judge = false
      } else if (is_absolute_low_inventory || is_relatively_low_inventory) {
        judge = true
      }
      // TODO
      return judge && !this.pageComponents.qtyConf.isShow
    },
    // 关联商品类型，0=普通码，1=大码，''=不显示关联商品
    relateGoodType() {
      const tag = this.relateGood && Number(this.relateGood.has_plus_size_tag)

      if ([0, 1].indexOf(tag) > -1) {
        return tag
      }

      return ''
    },
    defaultTrueToSizeText() {
      return this.cccConfig.tips || this.productIntroData.detail?.productDetails?.find?.(item => String(item.attr_id) == '146')?.attr_value || ''
    },
    isShowSizeTipsText() {
      return !this.cccConfig.tips && this.isShowSizeTips?.isShow
    },
    isShowTrueToSizeStrong() {
      return !this.cccConfig.tips && this.isShowSizeTips?.showTrueToSizeStrong
    },
    // 尺码偏大偏小提示
    sizeDeviationTips() {
      if (this.cccConfig.tips) return this.cccConfig.tips
      if (this.isShowSizeTipsText) {
        return `${this.isShowSizeTips?.sizeTipsText || ''}${this.showHeelHeight ? '; ' + this.heelHeight : ''}`
      }
      
      if (this.showHeelHeight) {
        if (this.isShowTrueToSizeStrong) return this.heelHeight
        return `${this.defaultTrueToSizeText}${(this.cssRight || !this.defaultTrueToSizeText ? ' ' : '; ')}${this.heelHeight}`
      }
      if (this.isShowTrueToSizeStrong) return ''
      return this.defaultTrueToSizeText
    },
    // 闪购购买数量上限提示
    flashSaleTip() {
      let flashSaleData = this.productIntroData?.detail?.mall?.[
        this.mallCode
      ]?.promotionInfo?.find(i => i.typeId == 10)
      const newFlashPromotionData = this.productIntroData?.newFlashPromotion || this.newFlashPromotion || {}
      const newFlashPromotionPriceWithSymbol = newFlashPromotionData?.price?.amountWithSymbol || ''
      const onlySpPromotion = [2, 3, 4].includes(flashSaleData?.flashType) && !newFlashPromotionPriceWithSymbol // 并未享受到闪购活动价
      if (!Number(flashSaleData?.singleNum)) return ''
      if (onlySpPromotion) return ''
      let currentTime = Date.now() / 1000
      let limit_text = flashSaleData.limitMode
        ? this.replaceTemplate(
          flashSaleData.singleNum,
          this.language.SHEIN_KEY_PC_24113
        )
        : this.replaceTemplate(
          flashSaleData.singleNum,
          this.language.SHEIN_KEY_PC_17383
        )
      let purchaseLimitText = flashSaleData ? limit_text : null

      return purchaseLimitText &&
        Number(flashSaleData.buyLimit) > Number(flashSaleData.soldNum) &&
        Number(currentTime) < Number(flashSaleData.endTimestamp)
        ? purchaseLimitText
        : ''
    },
    // 一口价购买数量上限提示
    underPriceTip() {
      const { productIntroData, mallCode, replaceTemplate, language } = this
      const underPriceInfo = productIntroData?.detail?.mall?.[
        mallCode
      ]?.promotionInfo?.find(i => i.typeId == 30)
      const limitNum = Number(underPriceInfo?.singleNum)
      if (isNaN(limitNum) || !limitNum) {
        return ''
      }
      const limitText = underPriceInfo.limitMode
        ? replaceTemplate(limitNum, language.SHEIN_KEY_PC_24113)
        : replaceTemplate(limitNum, language.SHEIN_KEY_PC_17383)
      return limitText || ''
    },
    relateGoodsName() {
      const brand =
        (this.productIntroData &&
          this.productIntroData.detail &&
          this.productIntroData.detail.brand) ||
        ''
      if (this.isRw) {
        return brand + ' ' + this.relateGood.goods_name
      } else {
        return this.relateGood.goods_name
      }
    },
    // 尺码自有推荐类型 0:鞋子 1:内衣。。。
    selfRecommendType() {
      let showSelfRecommend = this.productIntroData?.rule_id != 0 // 0为展示推荐，否则不展示推荐
      return (showSelfRecommend && this.productIntroData?.rule_type) || ''
    },
    isOneSize() {
      const skcSaleAttr = this.skcSaleAttr || []
      const sizeAttr = skcSaleAttr?.find(item => {
        return item.attr_id == 87
      })
      return sizeAttr?.attr_value_list?.some(
        item => item.attr_value_name === 'one-size'
      )
    },
    plusSizeRuleList() {
      return this.plusSizeRule?.[this.currentSelectedCountry] || []
    },
    showQuickShipStatus() {
      const { mallCode } = this
      const { mall = {} } = this.productIntroData?.detail || {}
      return this.showQuickShip && (mall?.[mallCode]?.skcQuickShip || this.isJpQuickShip)
    },
    heightSizeMap() {
      const baseCmOrg = this.sizeInfoDes?.['basicAttribute']?.['base_code_info'] || []
      const baseInchOrg = this.sizeInfoDes?.['basicAttribute']?.['base_code_info_inch'] || []
      let heightKey = this.language.SHEIN_KEY_PC_25650
      let sizeMap = {}
      if(this.currentSizeUnit === 1) {
        baseInchOrg.forEach(item => {
          sizeMap[item.size] = this.formatHeightSize(item[heightKey] || '', 'IN')
        })
      }else {
        baseCmOrg.forEach(item => {
          sizeMap[item.size] = this.formatHeightSize(item[heightKey] || '', 'CM')
        })
      }
      return sizeMap
    },
    longsize () {
      return this.productIntroData?.screenAbtConfig?.longsize?.p?.longsize || ''
    },
    longsizeTowLine () {
      return this.longsize === 'twofold' || this.longsize === 'twounfold'
    },
    hideOneSize() {
      const { polyAttrSize, skcSaleAttr } = this
      const isOneSize = polyAttrSize?.length == 1 && polyAttrSize[0]['attr_value_name_en'] == 'one-size' && skcSaleAttr?.length == 1 && skcSaleAttr[0]?.attr_id == 87
      return !!(this.config?.hideOneSize && isOneSize)
    }
  },
  watch: {
    goodsId(newVal, oldVal) {
      const unWatch = this.$watch('dataMap', () => {
        unWatch()
        this.$nextTick(() => {
          if (oldVal && newVal != oldVal && this.openAutoCheckMall && !this.chooseCheck(-1, '', '', this.mallCode)) {
            const hasStockMall = this.productIntroData?.detail?.mall_info?.find((_) => !!this.chooseCheck(-1, '', '', _?.mall_code))
            const mallCode = hasStockMall?.mall_code || ''
            if (mallCode === '') return
            this.mallClick({ 
              mallCode,
              mallStock: this.chooseCheck(-1, '', '', mallCode)
            })
          }
        })
      })
    },
    findSimilarFloatInfo: {
      immediate: true,
      handler(val) {
        if (this.from !== 'detail') return
        const { mode, show } = val || {}
        if (mode === 'modal' && show) {
          ProductFindSrimilarFloat?.showModal?.(this.findSimilarBind)
        }else {
          ProductFindSrimilarFloat?.hideModal?.()
        }
      }
    },
    country(val) {
      this.currentSelectedCountry = val
    },
    newOpen: {
      immediate: true,
      handler(val) {
        if (val) {
          this.isNewGoods = val
          this.notSelectedTipsAnim = false
          this.sizeInfoIndexBak = '{}'
        }
      }
    },
    externalSizeInfoIndex: {
      immediate: true,
      handler(val) {
        if (
          val &&
          Object.keys(val).length > 0 &&
          JSON.stringify(val || {}) != JSON.stringify(this.sizeInfoIndex)
        ) {
          this.needSetSizeIndex = val
          if (!this.firstCompose) {
            this.$nextTick(() => {
              this.initSizeInfoIndex()
            })
          }
        }
      }
    },
    externalSkuCode: {
      immediate: true,
      handler(val) {
        if (val && val != this.skuInfo.sku_code) {
          this.needSetSkuInfo = val
          if (!this.firstCompose) {
            this.$nextTick(() => {
              this.initSizeInfoIndex()
            })
          }
        }
      }
    },
    checkNotSelectedTips(val) {
      if (val) {
        this.checkNotSelectedSize()
      } else {
        this.notSelectedTips = []
      }
    },
    'productIntroData.getOtherOptions'() {
      this.relateGoodsInit()
    },
    saleAttrList() {
      this.preCalSizePopLeft()
      this.setFoldSizes()
    },
    sizeGuideModal(val) {
      this.$emit('openSizeGuide', val)
      if (!val && this.showEditSizeWhenClose) {
        let ruleType = this.productIntroData?.rule_type
        this.showSizeRecommend = this.isFromRecommend && ruleType == 0
        this.showBraSizeRecommend = this.isFromRecommend && ruleType == 1
        this.showEditSizeWhenClose = false
      }
    },
    attrSizeList(val) {
      if (val && val.hasOwnProperty('sale_attr_list')) {
        this.init()
      }
    },
    skuNoStock() {
      this.init()
    },
    hasLocalSize(val) {
      this.$emit('handleData', { hasLocalSize: val })
    },
    cccConfig(newVal, oldVal) {
      if (newVal?.isReady && newVal?.isReady !== oldVal?.isReady) {
        this.exposeNewTips()
      }
    }
  },
  async mounted() {


    this.init()

    //  getData
    this.relateGoodsInit()
    this.getHomeDetailSize() // 家居品类的额外size获取

    await this.getSizeAllAbt()
    this.recommendInit()

    this.handleSizeErrorAnimEnd()

    let sizeUnit = localStorage.getItem('selectedUnit')
    this.currentSizeUnit = sizeUnit
      ? Number(sizeUnit)
      : Number(this.sizeInfoDes.sizeUnit)

    if (this.almostSoldOut) {
      daEventCenter.triggerNotice({
        daId: '1-8-6-29'
      })
    }

    if (this.skuList && this.skuList.length && this.sizeDeviationTips) {
      daEventCenter.triggerNotice({
        daId: '1-8-6-68'
      })
    }

    this.currentSelectedCountry = this.country
    this.$emit('handleData', { hasLocalSize: this.hasLocalSize })
    // fix: 商详才注册事件提供给其他模块调用，不然会同时触发加车跟商详的size Guide弹窗
    if(this.from === 'detail') eventBus.on('sizeGuideModalShow', this.sizeGuideModalShow)
    this.setFoldSizes()

    if(this.from === 'detail'){
      window.addEventListener('scroll', this.closePopover)
    }
  },
  unmounted() {
    if(this.from === 'detail'){
      window.removeEventListener('scroll', this.closePopover)
    }
  },
  beforeUnmount() {
    eventBus.off('sizeGuideModalShow', this.sizeGuideModalShow)
  },
  methods: {
    ...mapActions(['updateExposedList', 'autoGetCouponAtAddBag']),
    closePopover: debounce({
      func: function() {
        this.$refs['popoverRef']?.forEach(_ => _.close?.())
      },
      wait: 300,
      options: { leading: true }
    }),
    oneLocalSizeDesc(subItem, attr_id) {
      const { defaultSize, localSize } = this.calcSize(subItem, attr_id)
      if (this.config.onlyOneLocalSizeNewOn || !this.config.showOnlyOneLocalSize || !this.currentTabLocalIsActive || !localSize) {
        return ''
      }
      return template(`<strong>${defaultSize}</strong>`, this.config?.localSizeList?.country_code, `<strong>${localSize}</strong>`, this.language.SHEIN_KEY_PC_32152 || `Size labeled on item is '{0}' same as {1} '{2}' you selected.`)
    },
    showSalePrice (relateGood) {
      if (relateGood?.newFlashPromotion?.price) {
        return true
      }
      if (relateGood?.flashZonePromotion?.price) {
        return true
      }
      if (relateGood.salePrice.amountWithSymbol !== relateGood.retailPrice.amountWithSymbol) {
        return true
      } 
      return false
    },
    getSalePrice (relateGood) {
      if (relateGood?.newFlashPromotion?.price) {
        return relateGood?.newFlashPromotion?.price?.amountWithSymbol
      }
      if (relateGood?.flashZonePromotion?.price) {
        return relateGood?.flashZonePromotion?.price?.amountWithSymbol
      }
      return relateGood.salePrice.amountWithSymbol || ''
    },
    async getSizeAllAbt() {
      // eslint-disable-next-line @shein-aidc/abt/abt
      const abtInfo = await abtservice.getUserAbtResult({
        posKeys: 'Selfsizerecommended'
      })
      if (abtInfo.Selfsizerecommended?.param == 'type=C') return
      this.sizeRecommendType =
        abtInfo.Selfsizerecommended?.param == 'type=A' &&
        this.productIntroData.rule_id != 0
          ? 1
          : 2
    },
    recommendInit() {
      const self = this.sizeRecommendType == 1
      // const allowThird = window.pdpAllowedLangs?.includes(gbCommonInfo.SiteUID)
      const method = self ? 'self' : ''

      if (this.sizeRecommendType == 2 && !this.isRw) {
        !method && this.loadThirdSizeRecommend('third_party')
        const size = sessionStorage.getItem('third_size_recommend')
        if (
          sessionStorage.getItem('third_size_recommend') &&
        sessionStorage.getItem('third_size_enable')
        ) {
          const item = this.polyAttrSize?.find?.(
            el => el.attr_value_name === size
          )
          if (
            item &&
          this.chooseCheck(
            87,
            this.sizeInfoIndex['87'].attr_name,
            item.attr_value_name,
            '',
            item.attr_value_id,
          ) > 0
          ) {
            this.setSize(item)
          }
        }
      }

      method && this.exposeSizeRecommend(method)
    },
    relateGoodsInit() {
      if (
        this.productIntroData.getOtherOptions?.length &&
        !this.isHiddenPlusSize
      ) {
        this.getRelateGood()
      } else {
        this.relateGood = null
      }
    },
    handleClickSizeGroupItem(item) {
      const { findPerfectFitScene, goodsName, goods_id, cat_id } = item || {}
      if(findPerfectFitScene) {
        const url =
          this.langPath + getGoodsUrl(goodsName, goods_id, cat_id)
        if (this.isMobile) {
          location.href = url
        } else {
          window.open(url)
        }
      } else {
        this.$emit('handleClickSizeGroupItem', item)
      }
    },
    handleUpdateQuickShip(status) {
      this.$emit('updateQuickShip', status)
    },
    mallClick(mallInfo) {
      // 待定要不要执行init
      this.$emit('mallClick', mallInfo)
    },
    openSizeGuideFromSizeTips() {
      if (this.isShowSizeTipsText) {
        this.sizeGuideModalShow(undefined, { fromScene: 'cart_tips' })
        daEventCenter.triggerNotice({
          daId: '1-8-6-137',
          extraData: {
            type: this.isShowSizeTips.isLargeSizeTips ? 1 : 2
          }
        })
      }
    },
    openReviews() {
      if(this.from === 'detail') {
        daEventCenter.triggerNotice({
          daId: '1-8-6-137',
          extraData: {
            type: 3
          }
        })
        const dom = document.querySelector('.common-reviews')
        if (dom){
          let y = dom.getBoundingClientRect().y
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          const headerHeight = document.querySelector('.j-c-header1').offsetHeight
          if (y < headerHeight) {
            y = y - headerHeight
          }
          window.scrollTo({
            behavior: 'smooth',
            top: y + scrollTop
          })
        }
      }
    },
    exposeNewTips() {
      if (!this.defaultTrueToSizeText && this.isShowSizeTips?.isShow) {
        daEventCenter.triggerNotice({
          daId: '1-8-6-138',
          extraData: {
            type: this.isShowSizeTips?.isLargeSizeTips ? 1 : 2,
          }
        })
      }
    },
    getShowSizeTipsTextAnalysis({ id, isStrong }) {
      const type = isStrong ? 3 : this.isShowSizeTips.isLargeSizeTips ? 1 : 2
      return {
        id,
        data: {
          type
        }
      }
    },
    exposeSizeRecommend(method) {
      daEventCenter.triggerNotice({
        daId: '1-8-6-77',
        extraData: {
          goods_id: this.currentGoodsId,
          method
        }
      })
    },
    handleValueChange(val, subItem) {
      if (val) {
        const flag = subItem.size_desc && subItem.size_desc.length
        if (!flag) {
          [...this.$refs['popoverRef']].forEach(vm => vm.close())
        }
      }
    },
    init() {
      this.dataMap = {}
      this.skuMap = {}
      this.dataIdMap = {}
      this.skuIdMap = {}
      this.dataMapOrder = []

      const isNewGoods = this.isNewGoods
      if (isNewGoods) {
        this.isNewGoods = false
        this.sizeInfoIndex = {}
      }

      this.setSaleAttrList()
      this.getSizeOptionalChain()
      this.initSizeInfoIndex()

      this.$nextTick(() => {
        this.isComplete = true
      })
      daEventExpose.resetAll?.('expose_goods_detail_select_otherattr_code')
    },
    setSaleAttrList() {
      const attrSizeList = JSON.parse(JSON.stringify(this.attrSizeList)) // 升级 Vue 3 时新增代码，深拷贝一份，否则会导致被 watch 无限循环
      let { sale_attr_list = {} } = attrSizeList
      let { is_on_sale, stock, secondSaleAttributes } = this.productIntroData?.detail
      const { showAttrImgPopover } = this
      const isAllSoldout = Number(is_on_sale) === 0 || Number(stock) === 0
      sale_attr_list = sale_attr_list?.[
        this.productIntroData?.detail?.goods_id
      ] || {
        skc_name: '',
        skc_sale_attr: [],
        sku_list: []
      }
      sale_attr_list.sku_list.map((sku = {}) => {
        if (sku?.mall_stock?.length) {
          // 升级 Vue 3 时新增代码，判断是否存在
          const mall = {}
          for (let i = 0; i < sku.mall_stock.length; i++) {
            const mallCode = sku.mall_stock[i].mall_code
            if (this.productIntroData?.detail?.mall?.[mallCode]) {
              const price =
                sku.mall_price?.find(price => price.mall_code == mallCode) ||
                {}
              const stockNull = this.skuNoStock?.[sku.sku_code]?.[mallCode]
                ? { stock: 0 }
                : {}
              mall[mallCode] = {
                ...sku.mall_stock[i],
                ...price,
                ...stockNull,
                ...(isAllSoldout ? { stock: 0 } : {}),
                promotionInfo: sku.promotion_info
              }
            }
          }
          if (isAllSoldout) {
            sku.stock = 0
          }
          sku.mall = mall
        }
        return sku
      })

      if (showAttrImgPopover && sale_attr_list?.skc_sale_attr?.length) {
        sale_attr_list.skc_sale_attr = formatSkcSaleAttrPatchPicInfo(sale_attr_list?.skc_sale_attr, secondSaleAttributes)
      }

      this.saleAttrList = sale_attr_list
      this.$emit('handleData', {
        saleAttrList: sale_attr_list,
        polyAttrSize: this.polyAttrSize
      })
    },
    initSizeInfoIndex() {
      // 先初始化sizeInfoIndex, 取用户备份信息，用于color切换，再进行属性检测和覆盖
      if (this.sizeInfoIndexBak !== '{}') {
        const sizeInfoIndexBak = JSON.parse(this.sizeInfoIndexBak)
        for (let k in sizeInfoIndexBak) {
          if (!this.sizeInfoIndex?.[k]?.attr_value_id) {
            this.sizeInfoIndex[k] = sizeInfoIndexBak[k]
          }
        }
      }
      // 初始化数据
      this.saleAttrList.skc_sale_attr.forEach(attr => {
        if (
          !this.sizeInfoIndex[attr.attr_id] ||
          (this.sizeInfoIndex?.[attr.attr_id]?.attr_value_name &&
            this.chooseCheck(
              attr.attr_id,
              attr.attr_name,
              this.sizeInfoIndex[attr.attr_id].attr_value_name,
              '',
              this.sizeInfoIndex[attr.attr_id].attr_value_id,
            ) === undefined)
        ) {
          this.sizeInfoIndex[attr.attr_id] = {
            attr_name: attr.attr_name,
            attr_value_name: '',
            attr_value_id: ''
          }
        }
      })
      // 再根据skuInfo去修改选中值
      if (this.needSetSkuInfo) {
        const skuInfo = this.skuList.find(
          _ => _.sku_code == this.needSetSkuInfo
        )
        this.needSetSkuInfo = ''
        if (skuInfo) {
          skuInfo.sku_sale_attr.forEach(attr => {
            this.sizeInfoIndex[attr.attr_id] = {
              attr_name: attr.attr_name,
              attr_value_name: attr.attr_value_name,
              attr_value_id: attr.attr_value_id
            }
          })
          this.sizeEmit()
          return
        }
      }
      // 或者根据传递出去的externalSizeInfoIndex修改选中值
      if (Object.keys(this.needSetSizeIndex).length > 0) {
        this.skcSaleAttr.forEach(item => {
          const subItem = item.attr_value_list.find(
            _ =>
              _.attr_value_id ==
              this.needSetSizeIndex[item.attr_id].attr_value_id
          )
          if (subItem) {
            this.sizeInfoIndex[item.attr_id] = {
              attr_name: item.attr_name,
              ...subItem
            }
          } else {
            this.sizeInfoIndex[item.attr_id] = {
              attr_name: item.attr_name,
              attr_value_name: '',
              attr_value_id: ''
            }
          }
        })
        this.needSetSizeIndex = {}
      }
      let data = {}
      if (this.skuList.length === 1) {
        data = this.skuList[0].sku_sale_attr.reduce((prev, item) => {
          prev[item.attr_id] = item.attr_value_id
          return prev
        }, {})
      }
      for (let i = 0; i < this.skcSaleAttr.length; i++) {
        let attr = null
        if (this.skcSaleAttr[i].attr_value_list.length == 1) {
          attr = this.skcSaleAttr[i].attr_value_list[0]
        } else if (this.skuList.length === 1) {
          attr = this.skcSaleAttr[i].attr_value_list.find(
            _ => _.attr_value_id == data[this.skcSaleAttr[i].attr_id]
          )
        }
        if (attr) {
          this.sizeInfoIndex[this.skcSaleAttr[i].attr_id] = {
            ...this.sizeInfoIndex[this.skcSaleAttr[i].attr_id],
            attr_name: this.skcSaleAttr[i].attr_name,
            ...attr
          }
        }
      }
      this.sizeEmit()
    },
    mallCheck(str, isIdMap = false) {
      // 没有这种属性项+mall的组合
      if (!this.skcSaleAttr.length) return
      const mallList = this.productIntroData.detail.mall_info
      if (this.chooseCheck(-1, '', '', this.mallCode) === undefined) {
        const optionalMallCode = {}
        let hasStockMall = false
        let mallCode = ''
        // 提取有库存的并且可组合的mallCode
        const targetMapData = isIdMap ? this.dataIdMap : this.dataMap
        for (let k in targetMapData) {
          if (k && k.indexOf(str) > -1) {
            const attr = k.split(',')
            if (targetMapData[k] > 0) {
              hasStockMall = true
            }
            optionalMallCode[attr[attr.length - 1] || ''] = targetMapData[k]
          }
        }
        // mallList的优先级是降序的
        for (let i = 0; i < mallList.length; i++) {
          const mall_code = mallList[i].mall_code
          // 优先找有库存的mall
          if (hasStockMall) {
            if (optionalMallCode[mall_code]) {
              mallCode = mall_code
              break
            }
          } else {
            // 所有mall都没库存，选取优先级最高的那个mall
            if (optionalMallCode[mall_code] == 0) {
              mallCode = mall_code
              break
            }
          }
        }
        this.$toast(
          this.replaceTemplate(
            this.productIntroData?.detail?.mall?.[this.mallCode]?.mall_name,
            this.language.SHEIN_KEY_PC_22215
          ),
          1500
        )
        const mallStock = this.chooseCheck(-1, '', '', mallCode)
        this.mallClick({
          mallCode,
          mallStock
        })
        return mallStock
      }
      return this.chooseCheck(-1, '', '', this.mallCode)
    },
    sizeEmit(item = {}) {
      let str = ''
      let idStr = ''
      let attrs = []
      let skuInfo = {}
      for (let i = 0; i < this.dataMapOrder.length; i++) {
        const id = this.dataMapOrder[i]
        const sizeInfo = this.sizeInfoIndex[id]
        const { attr_value_name, attr_name, attr_value_id } = sizeInfo
        attrs.push({ attr_id: id, ...sizeInfo })
        if (attr_value_name) {
          str += attr_name + '__' + attr_value_name + ','
          idStr += attr_name + '__' + attr_value_id + ','
        } else {
          str += ','
          idStr += ','
        }
      }
      // 检测最新选择的销售属性和当前的mall能否组成一个正确的商品
      const mallStock = this.openIdMap ? this.mallCheck(idStr, true) : this.mallCheck(str)

      if (this.firstCompose || item) {
        // 首次备份一下
        this.sizeInfoIndexBak = JSON.stringify(this.sizeInfoIndex)
      }
      if (this.skuList.length == 1 && this.skcSaleAttr.length == 0) {
        skuInfo = { ...this.skuList[0], skc_name: this.saleAttrList.skc_name }
      }
      if (this.skuMap?.[str]) {
        skuInfo = {
          ...this.skuMap?.[str],
          skc_name: this.saleAttrList.skc_name
        }
      }
      if (this.openIdMap && this.skuIdMap?.[idStr]) {
        skuInfo = {
          ...this.skuIdMap?.[idStr],
          skc_name: this.saleAttrList.skc_name
        }
      }
      skuInfo?.sku_sale_attr?.forEach?.(item => {
        if (typeof item !== 'object') return
        // 最终显示在页面的属性name
        item.sku_calc_name = this.calcSize(item).text || ''
      })
      this.skuInfo = skuInfo
      this.$emit(
        'compose',
        JSON.parse(
          JSON.stringify({
            attrs,
            mallStock,
            skuInfo: this.skuInfo,
            externalSizeInfoIndex: this.sizeInfoIndex,
            curSelectAttr: item,
            isFirst: this.firstCompose
          })
        )
      )
      this.firstCompose = false
      return this.openIdMap ? idStr : str
    },
    allSubsets(length) {
      //生成销售属性可选集合
      let res = [[]]
      // const arr = [...Array(length).keys()]
      const arr = Object.keys(Array.apply(null, { length }))
      for (let i = 0; i < arr.length; i++) {
        const tempRes = res.map(subset => {
          let one = subset.concat([])
          if (subset.length !== arr.length) {
            one = new Array(arr.length)
          }

          one[i] = arr[i]
          return one
        })
        res = res.concat(tempRes)
      }
      return res.slice(1)
    },
    getSizeOptionalChain() {
      const skulist = this.skuList
      const pack = this.allSubsets(this.skcSaleAttr.length + 1)
      for (let i = 0; i < pack.length; i++) {
        for (let j = 0; j < skulist.length; j++) {
          let k = ''
          let idK = ''
          let isSku = true
          for (let s = 0; s < this.skcSaleAttr.length; s++) {
            if (isNaN(pack[i][s])) {
              k += ','
              idK += ','
              isSku = false
            } else {
              const attr = skulist[j].sku_sale_attr[s]
              if (!attr) {
                continue
              }
              
              k += attr.attr_name + '__' + attr.attr_value_name + ','
              idK += attr.attr_name + '__' + attr.attr_value_id + ','
              if (this.dataMapOrder[s] === undefined) {
                this.dataMapOrder[s] = attr.attr_id
              }
            }
          }
          // start----这里的skuMap是给销售属性用的，不区分mall时候的总库存
          if (isSku) {
            this.skuMap[k] = skulist[j]
            this.skuIdMap[idK] = skulist[j]
          }
          const stock = Number(skulist[j].stock)
          if (stock) {
            this.dataMap[k] = stock
            this.dataIdMap[idK] = stock
          } else {
            if (!this.dataMap.hasOwnProperty(k)) {
              this.dataMap[k] = 0
            }
            if (!this.dataIdMap.hasOwnProperty(idK)) {
              this.dataIdMap[idK] = 0
            }
          }
          // end

          // start----加了mall信息的更细致一层的skuMap提供给mall组件做检测用
          for (let mallCode in skulist[j].mall) {
            let kFork = k + mallCode
            let idKFork = idK + mallCode
            const stock = Number(skulist[j].mall[mallCode].stock)
            if (stock) {
              this.dataMap[kFork] = stock
              this.dataIdMap[idKFork] = stock
            } else {
              if (!this.dataMap.hasOwnProperty(kFork)) {
                this.dataMap[kFork] = 0
              }
              if (!this.dataIdMap.hasOwnProperty(idKFork)) {
                this.dataIdMap[idKFork] = 0
              }
            }
          }
        }
      }
    },
    chooseCheck(attrId, attrName, attrValueName, mallCode, attrValueId = '') {
      if (typeof window == 'undefined') return
      let str = ''
      let idStr = ''
      for (let i = 0; i < this.dataMapOrder.length; i++) {
        const id = this.dataMapOrder[i]
        if (id == attrId) {
          str += attrName + '__' + attrValueName + ','
          idStr += attrName + '__' + attrValueId + ','
          continue
        }
        const { attr_name, attr_value_name, attr_value_id } = this.sizeInfoIndex[id]
        if (attr_value_name) {
          str += attr_name + '__' + attr_value_name + ','
          idStr += attr_name + '__' + attr_value_id + ','
        } else {
          str += ','
          idStr += ','
        }
      }
      if (mallCode) {
        str += mallCode
        idStr += mallCode
      }
      if (this.openIdMap) {
        return this.dataIdMap[idStr]
      }
      return this.dataMap[str]
    },
    descCheck(desc) {
      const val = desc.replace(/(cm|inch)/g, '').trim()
      if (val && val !== '/') {
        return true
      }
      return false
    },
    onChangeFeedBackCountry(key) {
      this.isNewSizeLocal && this.$emit('handleData', { country: key })
    },
    sizeSelectClick() {
      daEventCenter.triggerNotice({
        daId: '1-8-6-128',
        extraData: {
          choose_type: !this.currentSelectedCountry ? 1 : 0,
          style_type: this.config.showOnlyOneLocalSize ? 'tab' : 'list' 
        }
      })
    },
    onChangeSelectCountry(key) {
      if (this.isNewSizeLocal) {
        // 新逻辑要各个本地尺码联动
        this.$emit('handleChangeLocalSize', key)
        daEventCenter.triggerNotice({
          daId: this.config.showOnlyOneLocalSize ? '1-8-6-128' : '1-8-6-129',
          extraData: {
            choose_type: key,
            style_type: this.config.showOnlyOneLocalSize ? 'tab' : 'list'
          }
        })
      }
      this.$emit('handleData', { sizeGuideCountry: key })
    },
    initRecommendSize({ size, isInit = false }) {
      this.recommendMySize = size
      if (this.userSelectSize || isInit) return
      const item = this.polyAttrSize?.find?.(el => el.attr_value_name === size)
      if (
        item &&
        sessionStorage.getItem('enable_size_recommend') &&
        this.chooseCheck(
          87,
          this.sizeInfoIndex['87'].attr_name,
          item.attr_value_name,
          '',
          item.attr_value_id,
        ) > 0
      ) {
        this.setSize(item)
      }
    },
    setSize(item) {
      if (this.isMobile) {
        this.agenSizeHover()
        // this.agenSizeHover(e, isEnter)
      }
      this.handleChoose(
        null,
        null,
        {
          attr_id: '87',
          attr_name: this.sizeInfoIndex['87'].attr_name
        },
        item,
        true
      )
    },
    editMySize() {
      // rule_type为0:鞋子 1:内衣
      this.showSizeRecommend = this.selfRecommendType == 0
      this.showBraSizeRecommend = this.selfRecommendType == 1
      this.reportSizeBI()
    },
    reportSizeBI() {
      const method = this.sizeRecommendType == 1 ? 'self' : 'third_ party'
      daEventCenter.triggerNotice({
        daId: '1-8-6-78',
        extraData: {
          goods_id: this.currentGoodsId,
          method
        }
      })
    },
    loadThirdSizeRecommend(method) {
      if (!window.pdpAllowedLangs?.includes(gbCommonInfo.lang)) return
      const _this = this
      asyncLoadFile({
        label: 'script',
        attrs: {
          async: 1,
          type: 'text/javascript',
          id: 'integrations-script',
          src: `//integrations.fitanalytics.com/shop/${
            WEB_CLIENT == 'shein' ? 'shein/jan2020' : 'romwe'
          }/pdp.js`
        }
      }).then(() => {
        setTimeout(() => {
          if (
            typeof window._fitAnalytics !== 'undefined' &&
            typeof window._fitAnalytics.widget === 'object'
          ) {
            window._fitAnalytics.widget.onWidgetClose = () => {
              const recommendSize =
                window._fitAnalytics.widget?.prevResults?.sizeCode
              recommendSize &&
                sessionStorage.setItem('third_size_recommend', recommendSize)
              if (recommendSize && !this.userSelectSize) {
                const item = this.polyAttrSize?.find?.(
                  el => el.attr_value_name === recommendSize
                )
                if (
                  item &&
                  this.chooseCheck(
                    87,
                    this.sizeInfoIndex['87'].attr_name,
                    item.attr_value_name,
                    '',
                    item.attr_value_id,
                  ) > 0
                ) {
                  this.setSize(item)
                }
              }
            }
            window._fitAnalytics.widget.onWidgetOpen = () => {
              sessionStorage.setItem('third_size_enable', '1')
              this.reportSizeBI()
            }
            document?.getElementsByClassName('fitanalytics__button') &&
              method &&
              _this.exposeSizeRecommend(method) // 第三方曝光埋点
          }
        }, 3000)
      })
    },
    /**
     * hover size
     */
    sizeHover(e, isEnter) {
      if (this.isMobile) {
        return
      }
      this.agenSizeHover(e, isEnter)
    },
    agenSizeHover(e, isEnter) {
      try {
        if (e) {
          let itemDom = typeof e?.target === 'string' ? document.querySelector(e.target) : e.target
          if (itemDom) {
            const wrap =
                this.from == 'detail'
                  ? document.body
                  : itemDom.closest('.sui-dialog__wrapper')
            if (isEnter) {
              let status = false
              // 这个 420 是 popover 宽度 320 加上 预设的 打小码 radio 宽度 100
              // 如果没有这个 100 会导致边界处理失效
              if (this.cssRight) {
                status =
                      parseInt(wrap?.offsetLeft) <=
                      parseInt(itemDom.offsetLeft + itemDom.offsetWidth) - 420
              } else {
                status =
                      parseInt(itemDom.offsetLeft) + 420 <
                      parseInt(wrap?.offsetWidth + wrap?.offsetLeft)
              }
              return status
            }
          }
        }
      } catch(e) {
        /** 防止气泡阻塞点击尺码 */
      }
    },
    preCalSizePopLeft() {
      if (!this.sizeInfoAtOuter) {
        // 弹窗内部边界逻辑
        this.$nextTick(() => {
          this.sizePopoverPlacemen.splice(0)
          this.sizePopoverPlacemen.push(
            this.agenSizeHover(
              { target: `.j-product-intro__size-radio-spopover_87_index0` },
              true
            )
              ? 'top-start'
              : 'top-end'
          )
          this.polyAttrSize.forEach((item, i) => {
            this.sizePopoverPlacemen.push(
              this.agenSizeHover(
                {
                  target: `.j-product-intro__size-radio-spopover_87_index${i +
                    1}`
                },
                true
              )
                ? 'top-start'
                : 'top-end'
            )
          })
          this.sizePopoverPlacemen.push(
            this.agenSizeHover(
              { target: `.j-product-intro__size-radio-spopover_87_index999` },
              true
            )
              ? 'top-start'
              : 'top-end'
          )
        })
      }
    },
    handleSizeHover(i, subItem, attrIndex) {
      const doms = document.querySelectorAll(
        `.j-product-intro__size-radio-spopover_87_index${i} .product-intro__size-popover-list`
      )
      // let index
      for (let i = 0; i < doms.length; i++) {
        if (doms[i].className.indexOf('lessthan3') > -1) {
          if (doms[i].scrollHeight > doms[i].clientHeight) {
            this.showDetail = true
            // index = i
            break
          }
        }
        if (doms[i].className.indexOf('morethan3') > -1) {
          if (doms[i].scrollWidth > doms[i].clientWidth) {
            this.showDetail = true
            // index = i
            break
          }
        }
        if (
          doms[i].className.indexOf('lessthan3') == -1 &&
          doms[i].className.indexOf('morethan3') == -1
        ) {
          this.showDetail = false
          break
        }
      }
      if (this.showDetail) {
        // 这里 index 不太准 后面要优化下...
        this.$refs['popoverRef']?.forEach(_ => _.calcPopoverPosition?.())
        // this.$refs['popoverRef'][index]?.popover?.calcPopoverPosition?.()
        return
      }
      this.showDetail = false

      if (!subItem?.attr_image) return
      const location = this.from === 'detail' ? 'page' : 'popup'
      daEventCenter.triggerNotice({
        daId: '1-8-6-197',
        extraData: {
          goods_id: this.goodsId,
          location,
          main_attr: attrIndex + 2, // 主销售属性上报1；次级上报2；3级上报3.。。
        },
      })
    },
    /**
     * 点击 Size
     */
    handleChooseTouch(e, isEnter, item, subItem) {
      if (this.isMobile) {
        this.handleChoose(e, isEnter, item, subItem, 'auto')
      }
    },
    handleChoose(e, isEnter, item, subItem, auto) {
      if (this.isMobile && !auto) {
        return
      }
      if (this.isMobile) {
        this.agenSizeHover()
        this.agenSizeHover(e, isEnter)
      }
      if (
        this.chooseCheck(
          item.attr_id,
          item.attr_name,
          subItem.attr_value_name,
          '',
          subItem.attr_value_id,
        ) !== undefined
      ) {
        if (
          this.sizeInfoIndex[item.attr_id].attr_value_name ==
          subItem.attr_value_name
        ) {
          this.sizeInfoIndex[item.attr_id] = {
            attr_name: item.attr_name,
            attr_value_name: '',
            attr_value_id: ''
          }
        } else {
          this.sizeInfoIndex[item.attr_id] = {
            attr_name: item.attr_name,
            ...subItem
          }
          this.notSelectedTips = this.notSelectedTips.filter(
            _ => _ != item.attr_id
          )
        }
      } else {
        for (let attr_id in this.sizeInfoIndex) {
          if (attr_id == item.attr_id) {
            this.sizeInfoIndex[attr_id] = {
              attr_name: item.attr_name,
              ...subItem
            }
            this.notSelectedTips = this.notSelectedTips.filter(
              _ => _ != item.attr_id
            )
          } else {
            this.sizeInfoIndex[attr_id] = {
              ...this.sizeInfoIndex[attr_id],
              attr_value_name: '',
              attr_value_id: ''
            }
          }
        }
      }
      this.userSelectSize = true
      this.sizeEmit({
        attr_id: item.attr_id,
        ...subItem
      })
    },
    sizeGuideModalShow(isFromRecommend = false, config = {}) {
      this.renderSizeGuideModal = true
      this.isFromRecommend = isFromRecommend
      this.sizeGuideModal = true
      this.showSizeRecommend = false
      this.showBraSizeRecommend = false
      this.showEditSizeWhenClose = true

      if (this.from === 'quickAdd') {
        daEventCenter.triggerNotice({
          daId: '1-8-6-236',
          extraData: {
            from: sizeGuideFromSceneMap[config.fromScene]
          }
        })
      }
    },
    setGoodDetailFrom() {
      this.$emit('setGoodDetailFrom', 'addcar')
    },
    changeTips(stock) {
      return this.replaceTemplate(stock, this.language.SHEIN_KEY_PC_16400)
    },
    checkNotSelectedSize() {
      const notSelectedTips = []
      for (const k in this.sizeInfoIndex) {
        if (!this.sizeInfoIndex[k].attr_value_id) {
          notSelectedTips.push(k)
        }
      }
      this.notSelectedTips = notSelectedTips
      this.notSelectedTipsAnim = true
    },
    handleSizeErrorAnimEnd() {
      const sizeChoose = this.$refs['product-intro__size-choose']
      if (sizeChoose) {
        sizeChoose.forEach(item => {
          item.addEventListener(
            'webkitAnimationEnd',
            () => {
              this.notSelectedTipsAnim = false
            },
            false
          )
        })
      }
    },
    replaceTemplate: template,

    /**
     * 获取商品尺寸
     */
    fetchGoodSize(goodsIds) {
      const query = { goods_ids: goodsIds }
      return schttp({
        url: '/api/productInfo/sizes/get',
        params: query
      })
    },

    getHomeDetailSize() {
      schttp({
        url: '/api/productInfo/attrRelation/get'
      }).then(data => {
        if (data.code == 0 && data.info?.data) {
          const productDetails =
            this.productIntroData.detail?.productDetails || []
          const tackInfoDes = []
          const attrRelation = data.info.data.reduce((attrMap, curr) => {
            attrMap[curr.attr_value_id] = curr.attr_id
            return attrMap
          }, {})
          this.polyAttrSize.map(currAttr => {
            const currAttrValue =
              productDetails.find(
                _ => _.attr_id == attrRelation[currAttr.attr_value_id]
              ) || {}
            if (
              currAttrValue.attr_value_id &&
              attrRelation[currAttrValue.attr_value_id]
            ) {
              // 如果额外size还能在productDetails查找到对应的属性值，添加到sizeInfoDes中，作为属性的详情描述，可能包含多条
              tackInfoDes.push(
                productDetails
                  .filter(
                    _ => _.attr_id == attrRelation[currAttrValue.attr_value_id]
                  )
                  .reduce((prev, curr) => {
                    if (prev[curr.attr_name]) {
                      prev[curr.attr_name] += ', ' + curr.attr_value
                    } else {
                      prev[curr.attr_name] = curr.attr_value
                    }
                    return prev
                  }, {})
              )
            } else {
              tackInfoDes.push({})
            }
            return currAttr
          })
          this.tackInfoDes = tackInfoDes
          this.$emit('handleData', { tackInfoDes })
        }
      })
    },

    /**
     * 显示登陆弹窗
     */
    showLogin(addToWish) {
      this.$emit('showLoginModal', { addToWish })
    },

    /**
     * 显示快速加车弹窗
     * @param {Object} data 商品数据
     */
    showQuickviewModalTouch(e, isEnter, data) {
      if (this.isMobile) {
        this.showQuickviewModal(e, isEnter, data, 'auto')
      }
    },
    showQuickviewModal(e, isEnter, data, auto, scene) {
      let self = this
      if (this.isMobile && !auto) {
        return
      }
      if (this.isMobile) {
        this.agenSizeHover()
        this.agenSizeHover(e, isEnter)
      }
      if(scene !== 'sizegroup') {
        daEventCenter.triggerNotice({
          daId: '1-8-6-35'
        })
      }
      if (this.from == 'detail') {
        const mainGoodsId = this.productIntroData?.detail?.goods_id || ''
        this.$emit('openQuickView', {
          goodsId: data.goods_id,
          mainGoodsId,
          config: {
            isHiddenPlusSize: true
          },
          callback: {
            handleOpenLogin: this.showLogin,
            handleAddToCartDone(res){
              window.cart_module && window.cart_module.recart(res)
              self.autoGetCouponAtAddBag({ needToast: res?.code == 0, isMainGoods: mainGoodsId == data.goods_id })
            },
          },
          analysis: {
            pageType: 'ItemList',
            index: this.quickviewModal.index,
            sa: {
              code: 'detaiMain',
              activity_from: 'more_size'
            },
            ga: {
              eventCategory: '商品详情页'
            }
          }
        })
      } else {
        const { goods_url_name, goods_id, cat_id } = data
        const url =
          this.langPath + getGoodsUrl(goods_url_name, goods_id, cat_id)
        if (this.isMobile) {
          location.href = url
        } else {
          window.open(url)
        }
      }
    },

    /**
     * 快速加车弹窗选择颜色
     * @param {Object} data { good=商品数据 }
     */
    // quickViewSelectColor(data) {
    //     daEventCenter.triggerNotice({
    //         daId: '1-8-6-36',
    //         extraData: {
    //             sku: data.good.goods_sn || '',
    //         },
    //     })
    // },

    /**
     * 快速加车弹窗选择尺寸
     * @param {Object} data { size=尺寸, good=商品数据 }
     */
    // quickViewSelectSize(data) {
    //     daEventCenter.triggerNotice({
    //         daId: '1-8-6-37',
    //         extraData: {
    //             size: data.size,
    //         },
    //     })
    // },

    /**
     * 获取关联商品（大码/普通码）相关数据
     */
    getRelateGood() {
      let relateGoods = this.productIntroData.getOtherOptions || []
      // 筛选出关联商品
      relateGoods = relateGoods.filter(i => { 
        const series_info_list = i.series_info_list || []
        const list = series_info_list.filter(j => j.module_id == 4)
        return !!list.length
      })

      if (relateGoods.length < 1) {
        this.relateGood = null
        return
      }

      daEventCenter.triggerNotice({
        daId: '1-8-6-34'
      })

      const relateGood = relateGoods[0]
      let sizeInfo = []
      this.fetchGoodSize(relateGood.goods_id).then(sizeData => {
        let attrSize = sizeData?.sizes?.attrSize?.[relateGood.goods_id] || []
        let saleAttrList =
          sizeData?.sizes?.sale_attr_list?.[relateGood.goods_id] || {}
        let plusSizeRule =
          sizeData?.localSize?.[relateGood.goods_id]?.size_rule_list || []
        if (!attrSize.length) {
          const sku_list = saleAttrList?.sku_list || []
          const skc_sale_attr = saleAttrList?.skc_sale_attr || []
          const polyAttrSize =
            (skc_sale_attr.find(_ => _.attr_id == 87) || {}).attr_value_list ||
            []
          const sizeMap = sku_list.reduce((res, item = {}) => {
            if (item.mall_stock?.some(_ => _.stock > 0)) {
              const sizeItem = item.sku_sale_attr.find(_ => _.attr_id == 87)
              if (sizeItem) {
                res[sizeItem.attr_value_id] = sizeItem
              }
            }
            return res
          }, {})
          polyAttrSize.forEach(_ => {
            if (sizeMap[_.attr_value_id]) {
              sizeInfo.push(sizeMap[_.attr_value_id])
            }
          })
        } else {
          sizeInfo = attrSize
        }
        let sizeText = ''

        if (sizeInfo.length < 1) {
          this.relateGood = null
          return
        }

        if (sizeInfo.length < 2) {
          sizeText = [sizeInfo[0].attr_value_name || sizeInfo[0].attr_value]
        } else {
          sizeText = [
            sizeInfo[0].attr_value_name || sizeInfo[0].attr_value,
            '-',
            sizeInfo[sizeInfo.length - 1].attr_value_name ||
              sizeInfo[sizeInfo.length - 1].attr_value
          ]
        }

        relateGood.sizeText = sizeText
        this.relateGood = relateGood
        this.plusSizeRule = plusSizeRule
      })
    },
    calcSize(sizeItem, attr_id, shouldWeakenDefaultSize = false) {
      const { defaultlocalsize, showHightSize } = this.config || {}
      const { attr_value_name_en, attr_std_value, attr_value_name } = sizeItem || {}
      let defaultSize = attr_std_value ? `${attr_std_value} / ${attr_value_name}` : attr_value_name
      // 选择查看默认尺码时，本地尺码不存在 (所以当本地尺码存在时，必定为主尺码)
      let localSize = attr_id == this.sizeSaleAttrId ? this.localSizeMap?.[attr_value_name_en] || '' : ''
      let { main_size, sub_size } = sortMainSubSize(defaultSize, localSize, defaultlocalsize, shouldWeakenDefaultSize)

      // 如果不存在副尺码，且需要显示高度尺码，且尺码不包含/，且存在高度尺码
      if (sub_size === '' && showHightSize && main_size.indexOf('/') === -1 && this.heightSizeMap?.[attr_value_name]) {
        sub_size = `${this.heightSizeMap[attr_value_name]}`
        // 曝光身高尺码
        if(window !== 'undefined' && !this.isChildSizeExpose) {
          daEventCenter.triggerNotice({
            daId: '1-6-1-140'
          })
          this.isChildSizeExpose = true
        }
      }
      return {
        text: sub_size ? `${main_size} ${sub_size}` : main_size,
        defaultSize: defaultSize,
        localSize: localSize
      }
    },
    isLongSizeTwoLine (str) {
      if (!this.longsizeTowLine || !str || str.length < 10) return false
      return true
    },
    showSizeErrorTips(id, index) {
      const { notSelectedTips } = this
      if (notSelectedTips.includes(id)) {
        this.$nextTick(() => {
          this.$refs[`size-error-tips__hook-${index}`]?.length &&
            this.$refs[`size-error-tips__hook-${index}`][0].focus()
        })
        return true
      } else {
        this.$nextTick(() => {
          this.$refs[`size-error-tips__hook-${index}`]?.length &&
            this.$refs[`size-error-tips__hook-${index}`][0].blur()
        })
        return false
      }
    },
    getRelateSize(goods) {
      const { plusSizeRuleList } = this
      if (!this.isNewSizeLocal) return goods
      if (plusSizeRuleList.length) {
        const plus = plusSizeRuleList.find(({ name }) => {
          return name == goods && goods != 'one-size'
        })
        if (plus) {
          const shouldNoDefaultSizeAbt = ['local_size_no_default', 'default_size_no_default'].includes(this.config?.defaultlocalsize)
          if (shouldNoDefaultSizeAbt) return plus.correspond
          return `${plus.correspond}${this.config?.shouldWeakenDefaultSize ? `<span style="color: #959595; font-size: 12px;">(${goods})</span>` : `(${goods})`}`
        }
      }
      return goods
    },
    localSizeSelectHandle(value) {
      this.$emit('changeSelectCountry', value)
    },
    formatHeightSize(h, type) {
      if(!h.length) return ''
      try{
        let numberStr = h.replace(/(cm|inch)/g, '').trim()
        let numberArr = numberStr.split('-')
        if(isNaN(numberArr[0])) return ''
        if(numberArr.length === 2) { // 104.3-110.3 cm
          return `(${Number(numberArr[0]).toFixed()}-${Number(numberArr[1]).toFixed()}${type})`
        } else { // 104.3cm
          let singleNumber = numberArr[0].split(type)
          return `(${Number(singleNumber[0]).toFixed()}${type})`
        }
      }catch(error){
        return ''
      }
    },
    setFoldSizes () {
      if (this.from !== 'detail') return
      if (!this.longsize) return
      if (this.longsize === 'oneunfold' || this.longsize === 'twounfold') return
      let containerDoms = this.$refs['product-intro__size-choose']
      if (!containerDoms || !containerDoms.length) return
      this.foldBtn = this.skcSaleAttr.map(() => false)
      let getTop = dom => dom.getBoundingClientRect().top
      for (let i of Object.keys(containerDoms)) {
        let containerDom = containerDoms?.[i]
        let sizeItemDoms = containerDom?.children
        if (!sizeItemDoms || !sizeItemDoms.length) continue
        let containerDomTop = getTop(containerDom)
        let sizeItemTops = []
        for (let i of sizeItemDoms) {
          let temp = getTop(i)
          if (!sizeItemTops.includes(temp)) {
            sizeItemTops.push(temp)
          }
        }
        if (sizeItemTops.length < 5) continue
        containerDom.style.height = `${sizeItemTops[4] - containerDomTop}px`
        this.foldBtn[i] = true
        this.foldIndex = i
        daEventCenter.triggerNotice({
          daId: '1-8-6-169'
        })
        break
      }
    },
    setFoldShow (index) {
      if (this.foldBtn.every(item => !item)) return true
      if (this.foldIndex < 0) {
        return true
      } else {
        return this.foldIndex >= index ? true : false
      }
    },
    handleOpenFold () {
      this.foldBtn = this.foldBtn.map(() => false)
      this.$refs['product-intro__size-choose'].forEach(item => item.style.height = 'auto')
      daEventCenter.triggerNotice({
        daId: '1-8-6-170'
      })
    },
    getSizegourpAnalysisData({ type, sizeGroupItem }) {
      let goods_size_list = '' // goods_id`size_group1`theme_type1
      const { theme_type = '', merge_theme_type = '', displayDesc, goodsId, mainGoodsId } = sizeGroupItem
      goods_size_list = `${goodsId}\`${displayDesc}\`${merge_theme_type || theme_type}`
      const data = {
        location: 'popup',
        source_goods_id: mainGoodsId,
        size_group_style: 'size_attr',
        goods_size_list
      }
      return {
        id: type === 'click' ? '1-8-6-199' : '1-8-6-198',
        data,
      }
    }
  },
  emits: [
    'changeSelectCountry',
    'update:currentUnit',
    'openSizeGuide',
    'handleData',
    'mallClick',
    'compose',
    'showLoginModal',
    'openQuickView',
    'updateQuickShip',
    'handleClickSizeGroupItem',
    'setGoodDetailFrom',
    'handleChangeLocalSize'
  ]
}
</script>

<style lang="less">
.product-intro {
  /*//   &__size-title{*/
  //       .country{
  //           text-transform: lowercase;
  //       }
  //   }
  &__shake{
   animation-name: goodsShake;
  }
  &__size-title {
    display: inline-flex;
    align-items: center;
    margin-bottom: 15px;
    height: 26px;
    font-size: 16px;
    font-weight: 700;
    // .text-overflow(); // TODO 为啥要超出省略呢
    span {
      font-weight: 400;
    }
  }
  &__size-titleSelect {
    margin-left: 6px;
  }
  &__size-deviation {
    display: inline-block;
    margin: 0 10px;
    color: #666;
    font-size: 12px;
    line-height: normal;
    &_below {
      margin: 0 0 15px 0;
    }
  }
  &__size-tips-bold{
    font-weight: bold;
    color: #000000;
  }
  &__size-deviation-icon {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    font-size: 14px;
  }
  &__undersize-deviation-tipsstrong {
    display: inline;
    align-items: center;
    font-size: 12px;
    line-height: normal;
    .tipsstrong-span {
      color: #C44A01;
      // color: @sui_color_micro_emphasis;
      margin-right: 2px;
    }
    .like-icon {
      margin-right: 2px;
      font-size: 14px !important;/* stylelint-disable-line declaration-no-important */
    }
    .more-icon {
      vertical-align: middle;
    }
    &.cursor-pointer {
      cursor: pointer;
    }
  }
  &__start-line {
    display: flex;
    align-items: center;
    margin-top: 2px;
  }
  &__size-rec {
    cursor: auto;
    width: auto;
    margin-left: 0px;
    margin-bottom: 0px;
    transform-origin: 0px 0px;
    opacity: 1;
    transform: scale(1, 1);
    .button-text {
      display: inline-block;
      font-size: 12px;
      cursor: pointer;
      color: @sui_color_link;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__size-error-tips {
    .margin-l(10px);
    display: inline-block;
    color: #d53333;
    font-size: 13px;
    font-weight: normal;
  }
  &__check-size {
    display: inline-block;
    float: right;
    cursor: pointer;
    user-select: none;
  }
  &__size-radio-spopover {
    max-width: calc(100% - 2px);
    .product-intro__size-radio {
      margin: 0 12px 12px 0;
    }
  }
  &__size-radio {
    position: relative;
    min-width: 66px;
    min-height: 32px;
    line-height: 16px;
    padding: 8px 4px;
    border: 1px solid #e5e5e5;
    border-radius: 48px;
    font-size: 0;
    display: inline-block;
    text-align: center;
    max-width: 100%;
    cursor: pointer;
    .inner {
      font-size: 14px;
      display: inline-block;

      span {
        display: inline-block;
      }
    }
    &:hover {
      border: 1px solid #222;
      /* rw:begin*/
      border: 1px solid transparent;
      &::before {
        /* rw:begin*/
        position: absolute;
        .left(0);
        top: 0;
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        border: 2px solid #ff696e;
        border-radius: 16px;
        background: transparent;
        pointer-events: none;
      }
    }

    &.relate-good:hover {
      /* sh:begin*/
      border-color: #e5e5e5;
      color: #003569;
    }

    &.relate-good span {
      margin-left: 3px;
    }
    &.relate-good span:first-child {
      margin-left: 0;
    }
    &_soldout {
      color: #999;
      border: 1px dashed #bfbfbf;
      // cursor: not-allowed;
      &:hover {
        border: 1px dashed #bfbfbf;
        /* rw:begin*/
        border: 1px solid transparent;
      }
    }
    &_disabled {
      color: #999;
      border: 1px dashed #bfbfbf;
      background: #f6f6f6;
      cursor: not-allowed;
      &:hover {
        border: 1px dashed #bfbfbf;
        /* rw:begin*/
        border: 1px solid transparent;
      }
    }
    &_active {
      border: 1px solid transparent;
      color: @sui_color_main;
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        content: "";
        width: 100%;
        height: 100%;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border: 2px solid #222;
        box-sizing: content-box;
        border-radius: 16px;
        background: transparent;
        border: 2px solid @sui_color_main;
      }
      &:hover {
        border: 1px solid transparent;
      }
    }
    &_soldout {
      color: #999;
    }
  }
  &__size-radio-inner {
    max-width: 100%;
    font-size: 14px;
    padding: 0 12px;
    overflow: hidden;
    .icon-play1 {
      display: inline-block;
      transform-origin: center;
      transform: rotate(0deg) /* rtl:rotate(180deg) */;
    }
    .to-right {
      transform: rotate(90deg) /* rtl:ignore */;
    }
  }
  &__sizes-item-text--one { /* stylelint-disable-line */
    .text-overflow();
  }
  &__sizes-item-text--two { /* stylelint-disable-line */
    .line-camp();
    text-align: left;
  }
  &__sizes-more {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    color: @sui_color_gray_dark3;
    margin-bottom: 6px;
    cursor: pointer;
  }

  &__size-choose {
    overflow: hidden;
    .sui-popover__content {
      min-width: 320px;
    }
  }
  &__size-popover {
    cursor: default;
    padding: 15px;
    font-size: 12px;
    min-width: 320px;
    line-height: 1.33;
    .txt-l();
    display: none;

    // 临时兼容性处理，产品要求鼠标从按钮滑动到 popover 过程中 popover 不消失，最合理的做法是整体更改组件，但是时间不够
    // wiki.pageId=187793798
    &::before {
      background: rgba(255, 255, 255, 0);
      bottom: -10px;
      content: "";
      height: 10px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
  &__size-popover-content {
    line-height: 1.33;
    .txt-l();
  }
  &__size-popover-title {
    font-weight: bold;
  }
  &__size-popover-text {
    padding-bottom: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #222;
  }
  &__size-popover-desc {
    padding-bottom: 5px;
    font-size: 12px;
    color: #222;
  }
  &__size-popover-detail {
    color: #1860a7;
    padding-top: 6px;
    cursor: pointer;
  }
  &__size-outer {
    padding: 10px 10px 4px 10px;
    margin-bottom: 4px;
    background: #f7f8fa;
  }
  &__size-popover-list {
    font-size: 12px;
    margin-bottom: 4px;
    &.morethan3 {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &.lessthan3 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    li {
      display: inline;
      word-break: break-word;
      color: #222;
      line-height: 14px;
    }
    span {
      color: #767676 /* rw:#959595 */;
      padding-right: 2px;
    }
    span::after {
      content: ",";
    }
  }
  &__size-popover-li:last-of-type {
    span::after {
      content: ".";
    }
  }
  &__size-bra-info {
    color: #797979;
    span {
      color: #222;
    }
  }
  &__size-popover-titleL {
    color: #222;
    display: inline-block;
  }
  &__size-popover-listL {
    color: #797979;
    display: inline-block;
  }
  &__size-popover-stock {
    margin-top: 5px;
    color: @sui_color_highlight;
  }
  &__size-tips {
    padding: 3px 0 10px;
    font-size: 12px;
    color: @sui_color_highlight;
  }
  &__size-help {
    padding: 2px 0 12px;
  }
  .relate-good-popover {
    cursor: pointer;
  }
  &__size-popover-img {
    background-size: cover;
    background-repeat: none;
    float: left;
    /* sh:begin*/
    height: 70px;
    margin-right: 15px;
    width: 53px;

    /* rw:begin*/
    height: 90px;
    margin-right: 10px;
    width: 68px;
  }
  &__size-popover-stitle {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* sh:begin*/
    color: #222222;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;

    /* rw:begin*/
    color: #9b9b9b;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
  }
  &__size-popover-gtitle {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* sh:begin*/
    color: #222;
    font-family: ArialMT;
    margin-top: 10px;

    /* rw:begin*/
    color: #222;
    font-family: Roboto-Regular, Roboto;
    margin-top: 6px;
  }
  &__size-popover-gprice {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /* sh:begin*/
    color: #222;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    margin-top: 2px;

    /* rw:begin*/
    color: #333;
    font-family: Roboto-Bold, Roboto;
    font-weight: bold;
    margin-top: 6px;
  }
  &__size-guide-old,
  &__size-check {
    display: inline-block;
    color: @sui_color_link;
    font-size: 12px;
    cursor: pointer;
    line-height: 1;
  }
  &__size-guide-old {
    .margin-r(15px);
    color: @sui_color_link;
    display: flex;
    align-items: center;
    .product-intro__size-guide-old-t { /* stylelint-disable-line */
      .margin-l(5px);
    }
    &:hover {
      .product-intro__size-guide-old-t { /* stylelint-disable-line */
        text-decoration: underline;
      }
    }
    svg {
      vertical-align: sub;
      color: @sui_color_link;
      text-decoration: none;
      /* rw:begin*/
      // font-size: 12px;
      .margin-r(0);
      vertical-align: baseline;
    }
    &.title {
      font-weight: normal;
      margin-left: 20px;
    }
  }
}
@keyframes goodsShake {
  from,
  to {
      transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
      transform: translate3d(-6px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
      transform: translate3d(6px, 0, 0);
  }
}
</style>
